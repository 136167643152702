<template>
  <header class="header_area header_v1 transparent_header  all-page-padding">
    <div class="container">
      <div class="site_menu">
        <div class="row align-items-center">
          <div class="col-lg-2">
            <div class="brand">
              <router-link to="/en" class="logo"><img src="/static-en/images/logo_1.png" class="img-fluid" alt="">
              </router-link>
            </div>
          </div>
          <div class="col-lg-7 nowrap">
            <div class="primary_menu" id="menu">
              <nav class="main_menu">
                <ul>
                  <li :class="{'active_link':pathType==='home-en'}" class="menu-item">
                    <router-link to="/en" @click="$root.reload">Home</router-link>
                  </li>
                  <li :class="{'active_link':pathType==='about-en'}" class="menu-item">
                    <router-link to="/about-en" @click="$root.reload">About</router-link>
                  </li>
                  <li :class="{'active_link':(pathType === 'serviceMain-en')}" class="menu-item">
                    <router-link to="/service-en" @click="$root.reload">Service</router-link>
                    <ul class="sub-menu">
                      <li class="menu-item" v-for="(item,index) in subList" :key="index">
                        <router-link :to="`${item.link}`" @click="$root.reload">{{ item.name }}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li :class="{'active_link':pathType==='access-en'}" class="menu-item"><router-link to="/access-en" @click="$root.reload">Access</router-link></li>
                  <li :class="{'active_link':pathType==='contact-en'}" class="menu-item"><router-link to="/contact-en" @click="$root.reload">Contact</router-link>
                  </li>
                  <li><a href="/" style="background-image: url(/static-en/images/jp.svg);
                                                background-repeat: no-repeat;
                                                background-position: left;
                                                padding-left: 30px;">日本語</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile_wrapper">
        <div class="mobile_header">
          <div class="row align-items-center">
            <div class="col-6">
              <div class="brand_logo">
                <router-link to="/en"><img src="/static-en/images/logo_1.png" class="img-fluid" alt=""></router-link>
              </div>
            </div>
            <div class="col-6">
              <div class="menu_button">
                <div class="menu_icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidenav_menu" id="sidenav_menu">
          <div class="close_icon">
            <router-link to="#" class="close_btn"><i class="fas fa-times"></i></router-link>
          </div>
          <ul class="sidebar-menu" id="sidebar-menu">
            <li class="menu-item" :class="{'active':pathType==='home-en'}"><router-link to="/en" @click="closeSidenavMenu(true);$root.reload()">Home</router-link></li>
            <li class="menu-item" :class="{'active':pathType==='about-en'}"><router-link to="/about-en" @click="closeSidenavMenu(true);$root.reload()">About</router-link>
            </li>
            <li class="menu-item" :class="{'active':pathType==='serviceMain-en'}"><router-link to="/service-en">Service</router-link>
              <ul class="sub-menu" id="sub-menu">
                <li class="menu-item" v-for="(item,index) in subList" :key="index" :class="{'active':pathType === item.key}"><router-link :to="`${item.link}`" @click="closeSidenavMenu(false);$root.reload()">{{item.name}}</router-link></li>
              </ul>
            </li>
            <li class="menu-item" :class="{'active':pathType==='access-en'}"><router-link to="/access-en" @click="closeSidenavMenu(true);$root.reload()">Access</router-link></li>
            <li class="menu-item" :class="{'active':pathType==='contact-en'}"><router-link to="/contact-en" @click="closeSidenavMenu(true);$root.reload()">Contact Us</router-link></li>
            <li class="menu-item" ><a href="/" style="background-image: url(/static-en/images/jp.svg);
                                                background-repeat: no-repeat;
                                                background-position: left;
                                                padding-left: 30px;">日本語</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() {
    const route = useRoute();
    const pathType = ref('')
    const subList = ref([
      {name: 'Storage Battery System', link: '/service/battery-en', key: 'battery-en'},
      {name: 'Wind-Power Generation', link: '/service/wind-en', key: 'wind-en'},
      {name: 'Solar Power', link: '/service/solar-en', key: 'solar-en'},
    ])

    const init = ()=>{
      $(window).on('scroll',function() {
          var scroll = $(window).scrollTop();
          if (scroll < 100) {
            $(".header_area").removeClass("sticky");
          }else{
            $(".header_area").addClass("sticky");
          }
      });
      $(".menu_icon,.close_btn").on('click', function (e) {
        e.preventDefault();
        $(".menu_icon").toggleClass("active");
      });
      $(".menu_icon,.close_btn").on('click', function (e) {
        e.preventDefault();
         $(".sidenav_menu").toggleClass("active");
      });
      $(".sidenav_menu").mouseleave(function(){
          $(".sidenav_menu").removeClass("active");
      });
      $.sidebarMenu($('.sidebar-menu'))
    }
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = ['battery-en','wind-en','solar-en'].includes(newPathType) ? 'serviceMain-en' :newPathType;
      }
    )
    onMounted(()=>{
      init()
      pathType.value = route.meta.pathType;
    })

    function closeSidenavMenu (key){
      $(".sidenav_menu").removeClass("active");
      $(".sub-menu").removeClass("menu-open");
      if(key){
        $("#sub-menu").css("display","none");
      }
    }

    return {pathType,subList,closeSidenavMenu}
  }
}
</script>

<style>
.fedui-icon-locale {
    font-size: 30px;
    vertical-align: 0;
    cursor: pointer;
}
</style>
