import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementUI from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({
    offset: 20,
    delay: 300, // values from 0 to 3000, with step 50ms
    duration: 800 // values from 0 to 3000, with step 50ms
});
const HomeInfo = {
    Tel: '03-6402-3341',
    PostCode: '105-0013',
    Email: 'info@re100.info',
    Address: '東京都港区浜松町2-5-3',
    Building: 'リブポート浜松町 7F',
    CompanyName: 'REイニシアチブ株式会社',
    Representative: '原　明緒',
    Capital: '４,０００',
    CreateDate: '2019年4月3日',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.9196114071738!2d139.7542854660602!3d35.65435218020046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b196fea12a1%3A0x35f1bfd8447eca8!2zUkXjgqTjg4vjgrfjgqLjg4Hjg5bmoKrlvI_kvJrnpL4!5e0!3m2!1sja!2sjp!4v1652105801670!5m2!1sja!2sjp',
}

const HomeInfo_EN = {
    Tel: '03-6402-3341',
    PostCode: '〒105-0013 Tokyo, Minato-ku',
    Email: 'info@re100.info',
    Address: 'Hamamatsucho, 2 Chome−5−3',
    Building: 'Liveport Hamamatsucho 7F',
    CompanyName: 'RE Initiative Co., Ltd.',
    Representative: 'Hara Akio',
    Capital: '４,０００',
    CreateDate: 'April 3, 2019',
    MapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.9196114071738!2d139.7542854660602!3d35.65435218020046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b196fea12a1%3A0x35f1bfd8447eca8!2zUkXjgqTjg4vjgrfjgqLjg4Hjg5bmoKrlvI_kvJrnpL4!5e0!3m2!1sen!2sjp!4v1652105801670!5m2!1sen!2sen&language=en',
}

const app = createApp(App)
app.config.globalProperties.HomeInfo = HomeInfo
app.config.globalProperties.HomeInfo_EN = HomeInfo_EN
app.use(router).use(ElementUI).use(HomeInfo_EN).use(HomeInfo).use(AOS).mount('#app')