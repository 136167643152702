import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Contact from "@/views/Contact";
import About from "@/views/About";
import Service from "@/views/Service";
import Access from "@/views/Access";
import News from "@/views/News";
import NewsDetail from "@/views/NewsDetail";
import Solar from "@/components/Solar";
import Wind from "@/components/Wind";
import Battery from "@/components/Battery";
import ServiceMain from "@/components/ServiceMain";
// en
import Home_en from '@/views-en/Home.vue'
import Contact_en from "@/views-en/Contact";
import About_en from "@/views-en/About";
import Service_en from "@/views-en/Service";
import Access_en from "@/views-en/Access";
import News_en from "@/views-en/News";
import NewsDetail_en from "@/views-en/NewsDetail";
import Solar_en from "@/components-en/Solar";
import Wind_en from "@/components-en/Wind";
import Battery_en from "@/components-en/Battery";
import ServiceMain_en from "@/components-en/ServiceMain";

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            pathType: 'home',
            title: 'ホーム',
            content: {
                keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,RE,RE100,RE100 Info,RE100.info,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,FIT価格は年々下がり続ける中、FIPが始まり、再生可能エネルギー,RE INITIATIVE-ホーム,REイニシアチブ株式会社,REイニシアチブ - ホーム,,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,03-6402-3341,0364023341,東京都港区浜松町2-5-3,風力発電、太陽光発電を通じ地球環境に貢献する会社です',
                description: 'REイニシアチブ株式会社は蓄電システム、風力発電、太陽光発電を通じ地球環境に貢献する会社です。'
            }
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            pathType: 'contact',
            title: 'お問い合わせ',
            content: {
                keywords: 'リブポート浜松町 7F,Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecastE,RE100,RE100 Info,RE100.info,,再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,RE INITIATIVE-ホーム,REイニシアチブ株式会社,REイニシアチブ - ホーム,,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,お問い合わせ,商品カタログ、注文に関する質問などご遠慮なく連絡してください',
                description: '商品カタログ、注文に関する質問などご遠慮なく連絡してください'
            }
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            pathType: 'about',
            title: '会社概要',
            content: {
                keywords: '再生可能エネルギ,リブポート浜松町 7F,自由な発想で新製品を開発,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,RE INITIATIVE-会社概要,REイニシアチブ株式会社,REイニシアチブ - 会社概要,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,「REI- AI storage battery System」は、REイニシアチブのオリジナルブランドです,FIT価格は年々下がり続ける中、FIPが始まり、再生可能エネルギー',
                description: '2050年カーボンニュートラルZERO目標の達成に向けて、' +
                    '電力価格の上昇が見込まれます。' +
                    'FIT価格は年々下がり続ける中、FIPが始まり、' +
                    '蓄電の重要性が高まっています。' +
                    '再生可能エネルギーを有効に活用するために、' +
                    'REイニシアチブの蓄電システムをご提案します'
            }
        }
    },
    {
        path: '/service',
        name: 'service',
        component: Service,
        meta: {
            pathType: 'service',
            title: '事業内容',
            content: {
                keywords: 'リブポート浜松町 7F,Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,RE INITIATIVE-事業内容,REイニシアチブ株式会社,REイニシアチブ - 事業内容,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム',
                description: '太陽光発電, 風力発電, 蓄電システム'
            }
        },
        children: [{
                path: '/service',
                name: 'serviceMain',
                component: ServiceMain,
                meta: {
                    pathType: 'serviceMain',
                    title: '事業内容',
                    content: {
                        keywords: 'リブポート浜松町 7F,Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,Service,RE INITIATIVE-事業内容,REイニシアチブ株式会社,REイニシアチブ - 事業内容,REイニシアチブ,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,,太陽光発電, 風力発電, 蓄電システム',
                        description: '太陽光発電, 風力発電, 蓄電システム'
                    }
                }
            },
            {
                path: '/service/solar',
                name: 'solar',
                component: Solar,
                meta: {
                    pathType: 'solar',
                    title: '太陽光発電',
                    content: {
                        keywords: 'リブポート浜松町 7F,Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,Solar,RE INITIATIVE-太陽光発電,REイニシアチブ株式会社,REイニシアチブ - 太陽光発電,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム',
                        description: '太陽光発電, 風力発電, 蓄電システム'
                    }
                }
            },
            {
                path: '/service/battery',
                name: 'battery',
                component: Battery,
                meta: {
                    pathType: 'battery',
                    title: '蓄電システム',
                    content: {
                        keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,Battery,RE INITIATIVE-蓄電システム,REイニシアチブ株式会社,REイニシアチブ - 蓄電システム,REイニシアチブ,太陽光発電,,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio, 風力発電, 蓄電システム',
                        description: '太陽光発電, 風力発電, 蓄電システム'
                    }
                }
            },
            {
                path: '/service/wind',
                name: 'wind',
                component: Wind,
                meta: {
                    pathType: 'wind',
                    title: '風力発電',
                    content: {
                        keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,Wind,RE INITIATIVE-風力発電,REイニシアチブ株式会社,REイニシアチブ - 風力発電,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,',
                        description: '太陽光発電, 風力発電, 蓄電システム'
                    }
                }
            },
        ]
    },
    {
        path: '/access',
        name: 'access',
        component: Access,
        meta: {
            pathType: 'access',
            title: 'アクセス',
            content: {
                keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,FIT,当社蓄電池は、AIシステムを導入,〒105-0013,105-0013,1050013,Wind,RE INITIATIVE-アクセス,REイニシアチブ株式会社,REイニシアチブ - アクセス,REイニシアチブ,太陽光発電, ,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,風力発電, 蓄電システム,東京都港区浜松町2-5-3',
                description: '〒105-0013 東京都港区浜松町2-5-3'
            }
        }
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: {
            pathType: 'news',
            title: 'ニュース',
            content: {
                keywords: '再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,〒105-0013,105-0013,1050013,Wind,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,RE INITIATIVE-ニュース,REイニシアチブ株式会社,REイニシアチブ - ニュース,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,東京都港区浜松町2-5-3',
                description: 'REイニシアチブ - ニュース'
            }
        }
    },
    {
        path: '/news/:id',
        name: 'newsDetail',
        component: NewsDetail,
        meta: {
            pathType: 'newsDetail',
            title: 'ニュース詳細',
            content: {
                keywords: '再生可能エネルギ,自由な発想で新製品を開発,,長年の研究技術,カーボンニュートラルZERO目標,新しいビジネススタイルをご提案,ビジネススタイル提案,提案,当社蓄電池は、,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,AIシステムを導入,〒105-0013,105-0013,1050013,Wind,RE INITIATIVE-ニュース詳細,REイニシアチブ株式会社,REイニシアチブ - ニュース詳細,REイニシアチブ,太陽光発電, 風力発電, 蓄電システム,東京都港区浜松町2-5-3,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,',
                description: 'REイニシアチブ - ニュース'
            }
        }
    }, {
        path: '/en',
        name: 'home-en',
        component: Home_en,
        meta: {
            pathType: 'home-en',
            title: 'Home',
            content: {
                keywords: 'RE Initiative Co., ,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,,産業用蓄電,産業用蓄電システム,産業用蓄電装置,蓄電池,RE100,REイニシアチブ,太陽光オプティマイザー,オプティマイザー,太陽光火災,風力発電,小型風力発電,再生可能エネルギー自家消費,自家消費,再エネ自家消費,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,Ltd. is a company that contributes to the global environment through Storage battery systems, Wind power generation and Solar power generation. If you need  consultations Please feel free to contact us with any questions like product catalogs and orders.',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Home'
            }
        }
    },
    {
        path: '/contact-en',
        name: 'contact-en',
        component: Contact_en,
        meta: {
            pathType: 'contact-en',
            title: 'Contact',
            content: {
                keywords: '',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative contact'
            }
        }
    },
    {
        path: '/about-en',
        name: 'about-en',
        component: About_en,
        meta: {
            pathType: 'about-en',
            title: 'About',
            content: {
                keywords: '"REI- AI storage battery System" is the original brand of the RE initiative.' +
                    'Based on many years of research technology, we have developed new products with free ideas.' +
                    'We propose a new business style.' +
                    'Toward the achievement of the 2050 carbon neutral ZERO goal' +
                    'Electricity prices are expected to rise.' +
                    'As FIT prices continue to decline year by year, FIP has begun and the importance of electricity storage is increasing.' +
                    'In order to make effective use of renewable energy, we propose a power storage system under the RE initiative.' +
                    'Representative' +
                    'Akio Hara' +
                    'Tokyo Office' +
                    'Liveport Hamamatsucho 7F ,2-5-3' +
                    'Hamamatsucho, Minato-ku, ' +
                    'Tokyo 105-0013 ,Japan' +
                    'TEL. 03-6402-3341',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative about'
            }
        }
    },
    {
        path: '/service-en',
        name: 'service-en',
        component: Service_en,
        meta: {
            pathType: 'service-en',
            title: 'Service',
            content: {
                keywords: 'REI- AI storage battery System-100K,Storage Battery System,,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Service'
            }
        },
        children: [{
                path: '/service-en',
                name: 'serviceMain-en',
                component: ServiceMain_en,
                meta: {
                    pathType: 'serviceMain-en',
                    title: 'Srvice',
                    content: {
                        keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K' +
                            'Storage capacity,100kWh,（107kWh）' +
                            'Dimensions (mm),W:1450×D:1100×H:2200,' +
                            'Weight,2t,' +
                            'System PCS,200V　50/60Hz　3-phase 3-wire　60kW,output limit possible' +
                            'Output during a power outage,Maximum 60kW　,Automatic switching option' +
                            'Solar MPPT,DC/DC　60kW,Charge with direct current' +
                            'Operating temperature,-20～60℃,Air conditioner' +
                            'display,10 inch touch panel,' +
                            'AI learning,YES,' +
                            'Solar power generation forecast,YES,' +
                            'Installation,Indoor / outdoor,Salt damage painting is optional' +
                            'Fire detector,Smoke, temperature, gas detection,' +
                            'Fire extinguishing equipment,Heptafluoropropane,option' +
                            'Cooperation with other devices,Diesel power generation,option',
                        description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Srvice'
                    }
                }
            },
            {
                path: '/service/solar-en',
                name: 'solar-en',
                component: Solar_en,
                meta: {
                    pathType: 'solar-en',
                    title: 'Solar',
                    content: {
                        keywords: 'AI learning,Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 ,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K' +
                            'Storage capacity,100kWh,（107kWh）' +
                            'Dimensions (mm),W:1450×D:1100×H:2200,' +
                            'Weight,2t,' +
                            'System PCS,200V　50/60Hz　3-phase 3-wire　60kW,output limit possible' +
                            'Output during a power outage,Maximum 60kW　,Automatic switching option' +
                            'Solar MPPT,DC/DC　60kW,Charge with direct current' +
                            'Operating temperature,-20～60℃,Air conditioner' +
                            'display,10 inch touch panel,' +
                            'AI learning,YES,' +
                            'Solar power generation forecast,YES,' +
                            'Installation,Indoor / outdoor,Salt damage painting is optional' +
                            'Fire detector,Smoke, temperature, gas detection,' +
                            'Fire extinguishing equipment,Heptafluoropropane,option' +
                            'Cooperation with other devices,Diesel power generation,option',
                        description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Solar'
                    }
                }
            },
            {
                path: '/service/battery-en',
                name: 'battery-en',
                component: Battery_en,
                meta: {
                    pathType: 'battery-en',
                    title: 'Battery',
                    content: {
                        keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K' +
                            'Storage capacity,100kWh,（107kWh）' +
                            'Dimensions (mm),W:1450×D:1100×H:2200,' +
                            'Weight,2t,' +
                            'System PCS,200V　50/60Hz　3-phase 3-wire　60kW,output limit possible' +
                            'Output during a power outage,Maximum 60kW　,Automatic switching option' +
                            'Solar MPPT,DC/DC　60kW,Charge with direct current' +
                            'Operating temperature,-20～60℃,Air conditioner' +
                            'display,10 inch touch panel,' +
                            'AI learning,YES,' +
                            'Solar power generation forecast,YES,' +
                            'Installation,Indoor / outdoor,Salt damage painting is optional' +
                            'Fire detector,Smoke, temperature, gas detection,' +
                            'Fire extinguishing equipment,Heptafluoropropane,option' +
                            'Cooperation with other devices,Diesel power generation,option',
                        description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Battery'
                    }
                }
            },
            {
                path: '/service/wind-en',
                name: 'wind-en',
                component: Wind_en,
                meta: {
                    pathType: 'wind-en',
                    title: 'Wind',
                    content: {
                        keywords: 'Storage Battery System,Wind Power Generation,,原　明緒,REイニシアチブ株式会社,REイニシアチブ,RE100,RE,re,re100,Re info ,re100 info,東京都港区浜松町2-5-3,〒105-0013,info@re100.info,re100.info,リブポート浜松町 7F,RE Initiative Co., Ltd.,Liveport Hamamatsucho 7F,Hara Akio,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K',
                        description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Wind'
                    }
                }
            },
        ]
    },
    {
        path: '/access-en',
        name: 'access-en',
        component: Access_en,
        meta: {
            pathType: 'access-en',
            title: 'Access',
            content: {
                keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative Access'
            }
        }
    },
    {
        path: '/news-en',
        name: 'news-en',
        component: News_en,
        meta: {
            pathType: 'news-en',
            title: 'News',
            content: {
                keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative News'
            }
        }
    },
    {
        path: '/news-en/:id',
        name: 'newsDetail-en',
        component: NewsDetail_en,
        meta: {
            pathType: 'newsDetail-en',
            title: 'News Detail',
            content: {
                keywords: 'Storage Battery System,Wind Power Generation,Solar Power,REI- AI storage battery System,Solar power generation forecast,20 years,Correspondence years,Long life,100kw,high capacity,REI- AI storage battery System-200K',
                description: 'RE Initiative Co., Ltd.,RE Initiative , RE, RE Initiative News Detail'
            }
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/*
 * title 要素を制御する
 */
router.beforeEach((to, from, next) => {
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    document.title = to.meta.title + (location.pathname.endsWith('en') ? ' - RE Initiative' : ' - REイニシアチブ')
    next()
})
export default router