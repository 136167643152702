<template>
  <Header_en v-if="isEn" />
  <Header v-if="!isEn"/>
  <router-view v-if="isRouterAlive"/>
  <Footer_en v-if="isEn" />
  <Footer v-if="!isEn"/>
</template>

<style></style>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Header_en from "@/components-en/Header";
import Footer_en from "@/components-en/Footer";
import { nextTick, onMounted, ref} from "vue";
import { provide } from 'vue'
import { useRoute} from "vue-router"
export default {
  components: { Footer, Header,Footer_en, Header_en},

  setup() {
    const route = useRoute();
    const isRouterAlive = ref( true)
    function reload(){
      isRouterAlive.value = false
      nextTick( function (){
        isRouterAlive.value = true
      })
    }
    const isEn = ref(false)
    provide('reload', reload)
    onMounted(() => {
      new WOW().init();
      isEn.value = location.pathname.endsWith('en') || location.pathname.endsWith('en/')|| location.pathname.endsWith('en#');
    })
    isEn.value = location.pathname.endsWith('en') || location.pathname.endsWith('en/')|| location.pathname.endsWith('en#');
    if (isEn.value) {
      document.getElementById('scss-style').href = '/static-en/css/style.css';
    } else {
      document.getElementById('scss-style').href = '/static/css/style.css';
    }
    return{reload,isRouterAlive,isEn}
  },
  watch:{
    '$route':function(to,from){
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
};
</script>
