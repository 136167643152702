<template>
  <footer class="reinfo_footer ">
    <div class="widget_wrapper  all-page-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 footer-box">
            <div class="widget widegt_about">
              <div class="widget_title">
                <router-link to="/"><img alt="" class="img-fluid footerlogo" src="/static/images/footer_logo.png">
                </router-link>
              </div>
              <p>
                REイニシアチブ株式会社は蓄電システム、風力発電、太陽光発電を通じ地球環境に貢献する会社です。
              </p>
              <ul class="social" data-aos="fade-right" :data-aos-duration="500">
                <li><router-link to="#"><i class="fab fa-facebook-f"></i></router-link></li>
                <li><router-link to="#"><i class="fab fa-twitter"></i></router-link></li>
                <li><router-link to="#"><i class="fab fa-instagram"></i></router-link></li>
              </ul>
            </div>
          </div>
          <div v-show="showItems" class="col-lg-4 col-md-6 col-sm-12 footer-box1">
            <div class="widget widget_link">
              <div class="widget_title" data-aos="fade-right" :data-aos-duration="500">
                <h4>リンク</h4>
              </div>
              <ul>
                <li><router-link to="/about">会社概要</router-link></li>
                <li><router-link to="/service">事業内容</router-link></li>
                <li><router-link to="/access">アクセス</router-link></li>
                <li><router-link to="/contact">お問い合わせ</router-link></li>
              </ul>
            </div>
          </div>
          <div v-show="showItems" class="col-lg-4 col-md-6 col-sm-12 footer-box2">
            <div class="widget widget_contact">
              <div class="widget_title">
                <h4>お問い合わせ</h4>
              </div>
              <div class="contact_info">
                <div class="single_info">
                  <div class="icon">
                    <i class="fas fa-phone-alt fa-1-5"></i>
                  </div>
                  <div class="info">
                    <p>TEL: <a :href="'tel:'+HomeInfo.Tel">{{HomeInfo.Tel}}</a></p>
                  </div>
                </div>
                <div class="single_info">
                  <div class="icon">
                    <i class="fas fa-envelope fa-1-5"></i>
                  </div>
                  <div class="info">
                    <p>E-mail: <a :href="'mailto:'+HomeInfo.Email">{{HomeInfo.Email}}</a></p>
                  </div>
                </div>
                <div class="single_info">
                  <div class="icon">
                    &nbsp;<i class="fas fa-map-marker-alt fa-1-5  icon-style"></i>
                  </div>
                  <div class="info">
                    〒{{ HomeInfo.PostCode }} 東京Office <p>
                    <span>{{ HomeInfo.Address }}</span><span>{{ HomeInfo.Building }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright_text">
              <p>Copyright &copy;
                  {{(new Date().getFullYear())}}
                RE Initiative Co., Ltd. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-backtop></el-backtop>
    </div>
  </footer>
</template>

<script>
import {ref,onMounted,onUnmounted} from "vue";
export default {
  name: "Footer",
  setup(){
    const showItems = ref(true)
    function hidden() {
      if (window.innerWidth < 767) {
        showItems.value = false
      }else {
        showItems.value = true
      }
    }
    onMounted(() => {
      hidden();
    })
    window.addEventListener('resize', hidden)
    onUnmounted(() => {
      window.removeEventListener('resize', hidden)
    })
    return{showItems}
  }
}
</script>

<style scoped>

</style>
