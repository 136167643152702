<template>

  <!-- Start reinfo_service section -->
  <section class="reinfo_service service_v1 reinfo_pricing all-page-padding">
    <div class="shape_v2">
      <img src="/static/images/shape/shape_7.png" class="shape_7" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
            <h3><i class="fa fa-sun icon-hidde_v1"></i>
              太陽光発電</h3>
            <h4><i class="fa fa-sun icon-hidde"></i>Solar Power</h4>
          </div>
        </div>
      </div>
      <div >
          <div class="row">
            <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
              <h3 class="title-h3">
                屋根の上に太陽光パネルを設置していると火事の際、<br/>
                消防活動に支障がでるのをご存じですか？
                </h3>
                <h4 class="title-h4">
                  消防庁が消防活動時の太陽光発電設備に対する感電防止の対応
                </h4>
            </div>
            </div>
          </div>
          <!--img-->
          <div class="row solar pc-hide">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static/images/num1.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static/images/num1-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static/images/num2.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static/images/num2-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static/images/num3.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static/images/num3-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div class="row solar mobile-show">
            <div class="col-lg-4 col-md-6 col-sm-12"  style="display:block">
               <img src="/static/images/optimizer-title-0.png" style="width:100%" data-aos="fade-right" :data-aos-duration="500"/>
            </div>
          </div>
          <!--word-->
          <div class="row solar solar-word text-center" style="display:block">
            <img src="/static/images/optimizer0.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
          </div>
      </div>
      <!--オプティマイザーの重要性-->
      <div class="container" style="margin-top:30px"> 
        <div class="row optimizer pc-hide">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="text-center " data-aos="fade-right">
              <h3 data-aos="fade-right" :data-aos-duration="500" class="title-h4-text"><img src="/static/images/title-1.png" class="img-fluid" />
                <span class="icon-hidde" style="display: inline-table;">オプティマイザーの重要性</span>
              </h3>
              <h3 data-aos="fade-right" class="title-h4-text" :data-aos-duration="500" style="margin-top:-130px;margin-left: -30px;">
                  <img src="/static/images/title-1.png" class="img-fluid icon-hidde" style="display: inline-table;" />
                  オプティマイザーの重要性
              </h3>
              <h4 class="title-h4-text" data-aos="fade-right" :data-aos-duration="500" style="margin-top:-70px">
                  <img src="/static/images/title-1.png" class="img-fluid icon-hidde" style="display: inline-table;"/>
                  太陽パネルからの出力を<span class="h3-line">安全に切断できる</span>
              </h4>
            </div>
          </div>
        </div>
        <div class="row optimizer mobile-show">
            <div class="col-lg-12">
              <div :data-aos-duration="500" class="text-center " data-aos="fade-right">
                 <img src="/static/images/optimizer-title-1.png" style="width:95%"/>
              </div>
            </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static/images/optimizer.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
      <!--高効率に調節できる-->
      <div class="container" style="margin-top:30px">
        <div class="row">
          <div class="col-lg-12 pc-hide">
            <div :data-aos-duration="500" class="text-center" data-aos="fade-right">
              <h4 class="title-h4-text"><img src="/static/images/title-2.png" class="img-fluid" />
                太陽パネルごと電圧をに<span class="h3-line">高効率に調節できる</span>
              </h4> 
            </div>
          </div>
          <div class="col-lg-12 mobile-show">
             <img src="/static/images/optimizer-title-2.png" style="width:95%"/>
          </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static/images/optimizer1.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
      <div class="container" style="margin-top:30px">
        <div class="row">
          <div class="col-lg-12 pc-hide">
            <div :data-aos-duration="500" class="text-center" data-aos="fade-right">
              <h4 class="title-h4-text"><img src="/static/images/title-3.png" class="img-fluid" />
                太陽光パネルとパワコンなどの既存設備はそのまま使用可能
              </h4> 
            </div>
          </div>
          <div class="col-lg-12 mobile-show">
             <img src="/static/images/optimizer-title-3.png" style="width:100%"/>
          </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static/images/optimizer2.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
    </div>
     <div class="container" style="margin-top:30px">
      <div class="row">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">  
           <br/>
            <h3>オプティマイザー仕様</h3>
            <br/>
            <img src="/static/images/optimizer3.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:25px;">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
            <h3>切断器仕様</h3>
            <br/>
            <img src="/static/images/optimizer4.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
           </div>
        </div>
      </div>
     </div>
  </section>
  <!-- End reinfo_service section -->
</template>

<script>
export default {
  name: "Solar",
    data() {
      return {
        ww: window.innerWidth
      }
  }
}
</script>

<style scoped>

</style>
