<template>
  <div :data-aos-duration="500" class="agency-style" data-aos="fade-right">
    こちらは代理店専用ページです。<br/>
    代理店契約をご希望の方はお問い合わせページよりお問いわせください。
  </div>
</template>

<script>
export default {
  name: "Agency"
}
</script>