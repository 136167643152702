<template>
  <!-- Start reinfo_banner section -->
  <section class="reinfo_banner banner_v1 banner_p">
    <div class="home_banner_container" id="banner_content">
      <div class="hero_slider">
        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                      :src="isMobile? '/static/images/banner_1_mobile.png' : '/static/images/banner_1.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20">
                    <img src="/static/images/home_t.png?v=1.0.02" class="fontGrad-bg"/>
                    <span class="cWhite textShadow">蓄電システム</span></h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    不安定な自然エネルギーを効率よく利用する為に、蓄電システムは不可欠な存在です。蓄電システムに求められるものは、コスト・安全性・耐久性です。求められる全てを満足できるレベルで達成した、REイニシアチブの産業用蓄電システムは、当社独自のAIを搭載し、最適なシステムで運用最大限のコストパフォーマンスを実現します。蓄電池容量は最大１MWまで拡張でき、産業用蓄電システムの構築に最適です。
                  </p>
                  <router-link to="/Contact" class="reinfo_btn">お問い合わせ</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                     :src="isMobile? '/static/images/banner_2_mobile.png' : '/static/images/banner_2.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20"><span class="cWhite textShadow">風力発電</span></h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    風力発電は世界で最も活用されている自然エネルギーです。我が国では、一定の安定風速が得られる沿岸部に設置されていますが、今後は洋上や山の尾根など、より風速が得られ、また環境にも配慮した設置方法が求められています。REイニシアチブでは、パフォーマンスに優れ、環境にやさしい風力発電機の研究を行い、日本の自然環境に適した風力発電機の設置をご提案します。
                  </p>
                  <router-link to="/Contact" class="reinfo_btn">お問い合わせ</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                     :src="isMobile? '/static/images/banner_3_mobile.png' : '/static/images/banner_3.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20"><span class="cWhite textShadow">太陽光発電</span></h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    海外製品が主流となった太陽光発電ですが、日本の研究と技術開発によって生まれた自然エネルギーです。太陽光で発電を行い、海外生産により現在ではコストパフォーマンスに優れた再生可能エネルギーとして活用されています。昼間の発電に限り、雨天や積雪では発電出来ない為、需要と供給に差異が生じます。REイニシアチブの蓄電システムと組み合わせることにより、安定した自然エネルギーの供給が可能となります。
                  </p>
                  <router-link class="reinfo_btn" to="/Contact">お問い合わせ</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--
  <div class="banner-hidden">
      <div class="banner-home-01"></div>
      <div class="banner-home-02"></div>
  </div>-->
  <!-- End reinfo_banner section-->

  <section class="reinfo_experience service_v1 all-page-padding">
    <div class="container  pc_show">
      <div class="row" data-aos="fade-right" :data-aos-duration="500">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-american-sign-language-interpreting icon-hidde_v1"></i>
              企業理念
            </h3>
            <h4><i class="fa fa-american-sign-language-interpreting icon-hidde"></i>Company Philosophy</h4>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                私たちは<br/>自然エネルギーの普及に努め、自然環境を守り続けること。<br/>
                私たちに出来ることは<br/>自然エネルギーを求める人と共に、日本人の誠意と誠実をもって出来うる全てを行うこと。
                より良い未来を求める心が、世界の全ての人と繋がり、希望に満ちた明日を創ることを
                企業理念とします。
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>1</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>絶え間ない研究と経験に基づいて</h4>
              <h5>リアルタイムな情報分析</h5>
              <h6>お客さまへの継続的なサービスを提供します</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div class="row align-items-center" data-aos="fade-right" :data-aos-duration="500">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>経営理念</h4>
              <h5>未来のために</h5>
              <h6>お客さまから信頼される企業をめざして</h6>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>2</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                経験豊富なスタッフと、常に向上し続けるためのチームワークを形成し、活力のある企業グループを目指します。経営理念に基づいた企業活動を行い、優れた商品開発と安定したサービスを提供し続け、社会に貢献出来ることを喜びとします。私たちREイニシアチブは、リニューアブルエナジーの発展を率先して行う企業です。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                理念に基づいた事業を行い、発展させていく中で、透明性を大切に、信頼される会社経営を行います。個人情報、会社情報等の情報を保護し、法令の遵守はもちろんのこと社会的倫理規範を尊重し、公正な企業活動を行うと共に、反社会的勢力との関係は一切持ちません。
                私たちREイニシアチブは優れた製品を厳正し、お客さまにご提案し続けます。
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>3</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>企業行動指針</h4>
              <h5>誠意・誠実・安心</h5>
              <h6>
                お客さまに信頼されるサービスと、優れた製品とコストパフォーマンスを提供します。
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container  mobile-show">
      <div :data-aos-duration="500" class="row" data-aos="fade-right">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-american-sign-language-interpreting icon-hidde_v1"></i>
              企業理念
            </h3>
            <h4><i class="fa fa-american-sign-language-interpreting icon-hidde"></i>Company Philosophy</h4>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3 style="font-size:35px!important">1</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>絶え間ない研究と経験に基づいて</h4>
              <h5>リアルタイムな情報分析</h5>
              <h6>お客さまへの継続的なサービスを提供します</h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                私たちは<br/>
                自然エネルギーの普及に努め、自然環境を守り続けること。<br/>
                私たちに出来ることは<br/>
                自然エネルギーを求める人と共に、日本人の誠意と誠実をもって出来うる全てを行うこと。 より良い未来を求める心が、世界の全ての人と繋がり、希望に満ちた明日を創ることを 企業理念とします。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3 style="font-size:35px!important">2</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>経営理念</h4>
              <h5>未来のために</h5>
              <h6>お客さまから信頼される企業をめざして</h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                経験豊富なスタッフと、常に向上し続けるためのチームワークを形成し、活力のある企業グループを目指します。経営理念に基づいた企業活動を行い、優れた商品開発と安定したサービスを提供し続け、社会に貢献出来ることを喜びとします。私たちREイニシアチブは、リニューアブルエナジーの発展を率先して行う企業です。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3 style="font-size:35px!important">3</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>企業行動指針</h4>
              <h5>誠意・誠実・安心</h5>
              <h6>
                お客さまに信頼されるサービスと、優れた製品とコストパフォーマンスを提供します。
              </h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                理念に基づいた事業を行い、発展させていく中で、透明性を大切に、信頼される会社経営を行います。個人情報、会社情報等の情報を保護し、法令の遵守はもちろんのこと社会的倫理規範を尊重し、公正な企業活動を行うと共に、反社会的勢力との関係は一切持ちません。
                私たちREイニシアチブは優れた製品を厳正し、お客さまにご提案し続けます。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Start reinfo_about section -->
  <section class="reinfo_about about_v1  all-page-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="reinfo_img_box wow fadeInLeft">
            <img src="/static/images/about.png" class="img-fluid" alt=""/>
          </div>
        </div>
        <div class="col-lg-7 item_width_100">
          <div class="reinfo_content_box wow fadeInRight">
            <h3 data-aos="fade-right" :data-aos-duration="500">
              <i class="fa fa-thumbs-up"></i>
              当社ができること
            </h3>
            <p data-aos="fade-right" :data-aos-duration="500">
              カーボンニュートラルの実現のために、供給を制御する動きが、需要逼迫をまねき価格高騰の流れを作る中、コロナ禍からの経済再開により、石油需要増加による、発電コストも増加、電気代の高騰。
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              2050年カーボンニュートラルZEROを目標に、燃料価格の上昇が伴い電気価格の上昇が見込め、電気が高額で、販売が行われると市場予想がされます。
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              当社蓄電池は、気象データをもとに明日の太陽光発電量を予測します。
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              天候が悪く発電量が少なく、電力が不足する場合は、電気代の安い深夜電力で充電いたします。これにより悪天候の日でも確実に電力のピークカットが可能となります。
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              毎日の電力使用量と発電量をAIによる機械学習を行い、精度を高めていきます。
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              お客様の電力使用状況はお客様ごとに大きく異なり、設置スペース、停電時対策のご要望、
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              ご予算など様々なニーズにお応えする為に、お客様ごとの専用設計になってしまい高額になってしまいます。当社は、蓄電池、蓄電池パワコン、太陽光パワコン、停電用インバータをそれぞれパッケージ化して組み合わせや増設により、お客様ごとの要望を低コストでご提供いたします。
            </p>
            <div class="btn_center" data-aos="fade-right" :data-aos-duration="500">
              <router-link class="reinfo_btn" to="/about">もっと見る</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End deneb_about section -->

  <!-- Start reinfo_blog section -->
  <section class="reinfo_blog blog_v1  all-page-padding">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-12" data-aos="fade-right" :data-aos-duration="500">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-bell icon-hidde_v1"></i>
              お知らせ
            </h3>
            <h4><i class="fa fa-bell icon-hidde"></i>News</h4>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-right" :data-aos-duration="500">
        <div id="news"
          class="col-lg-4 col-md-6 col-sm-12 news_list_item"
          v-for="(item, index) in newsList"
          :key="index"
        >
          <router-link :to="`/news/${item.id}`">
            <div class="grid_item wow fadeInUp">
              <div class="reinfo_img news_list_imggit">
                <img :src="item.img" class="img-fluid" />
              </div>
              <div class="reinfo_info">
                <span class="date">{{ item.time }}</span>
                <h3 class="news_list_title">
                  <span>{{ item.title }}</span>
                </h3>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <br />
      <router-link to="/news" class="reinfo_btn" data-aos="fade-right" :data-aos-duration="500">もっと見る</router-link>
    </div>
  </section>
  <!-- End reinfo_blog section -->
  <!-- Start reinfo_cta section -->
  <section class="reinfo_cta contact_v1  all-page-padding">
    <div class="container">
      <div class="cta_wrapper">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="cta_content" data-aos="fade-right" :data-aos-duration="500">
              <h3>ご相談はこちらから</h3>
              <p>
                商品カタログ、注文に関する質問などご遠慮なく連絡してください。
              </p>
            </div>
          </div>
          <div class="col-lg-5" data-aos="fade-right" :data-aos-duration="500">
            <div class="button_box">
              <router-link to="/contact" class="reinfo_btn">お問合せ</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End reinfo_cta section -->
</template>


<script>
import { onMounted, ref } from "vue";
export default {
  name: "Home",
  setup() {
    const initSlick = () => {
      $(".hero_slider").slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        fade: true,
      });
    };
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      initSlick();
      getNewsList();
    });
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>
