<template>
  <div class="show-center">
    <!-- Start service_feature -->
    <section class="reinfo_service service_v1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center no-margin-bottom" data-aos="fade-right">
              <h3>
                <i class="fa fa-battery-full icon-hidde_v1"></i>
                蓄電システム
              </h3>
              <h4 :data-aos-duration="500" data-aos="fade-right">
                Storage Battery System
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--REI- AI storage battery System-->
    <section class="reinfo_about  battery-padding-top battery-padding-bottom">
      <div class="container">
         <div class="row align-items-center pc-show_v1">
            <img src="/static/images/hp_battery.png" style="width:100%;" />
         </div>
         <div class="row align-items-center mobile-show_v1">
            <img src="/static/images/hp_battery_m.png" style="width:100%;" />
        </div>
      </div>
    </section>
    <!--REI- AI storage battery System-200K-->
    <section class="reinfo_about battery_v1 battery-padding-top battery-padding-bottom_v1">
      <div class="container">
        <div class="row align-items-center">
          <!--Title-->
          <div class="col-lg-12">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-200K
              </h5>
            </div>
          </div>
          <!--Image-->
          <div class="col-lg-6">
            <div class=" align-center">
              <img class="img-200K" src="/static/images/200K.png"/>
            </div>
            <div :data-aos-duration="500" class="img-content" data-aos="fade-right">
              *内部イメージ
            </div>
          </div>
          <!--Table-->
          <div class="col-lg-6 subbox subbox-200k">
            <Table_el :columns="columns_200k" :titles="titles"/>
          </div>
        </div>
      </div>
    </section>

    <!--REI- AI storage battery System-100K-->
    <section class="reinfo_about battery_v1 battery-padding-top battery-padding-bottom_v1">
      <div class="container">
        <div class="row align-items-center">
          <!--Title-->
          <div class="col-lg-12">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-100K
              </h5>
            </div>
          </div>
          <!--image-->
          <div class="col-lg-6 align-center">
            <img src="/static/images/100k_1.png" width="60%"/>
          </div>
          <div class="col-lg-6 align-center">
            <img src="/static/images/100k-2.png" width="80%"/>
            <img src="/static/images/100k-3.png" style="
                        position: absolute;
                        z-index: 9;
                        top: 60%;
                        left: 60%;
                        height: 50%;"/>
          </div>
          <div class="col-lg-6 mobile-show_v1 ">
            <Battery_el :isMobie="true"/>
          </div>
          <div class="col-lg-6 subbox mobile-show_v1 subbox-100k">
            <Table_el :columns="columns_100k" :titles="titles"/>
          </div>

          <div class="col-lg-6 subbox pc_show_v1">
            <Table_el :columns="columns_100k" :titles="titles"/>
          </div>
          <div class="col-lg-6 pc_show_v1">
            <Battery_el :isMobie="false"/>
          </div>
          <!--Table-->
          <div class="col-lg-12" style="margin-top:50px">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-50K
              </h5>
            </div>
          </div>
          <div class="col-lg-6 subbox">
            <Table_el :columns="columns_50k" :titles="titles"/>
          </div>
          <div class="col-lg-6 align-center">
            <img src="/static/images/50k_1.jpg" style="width:70%"/>
          </div>
        </div>
      </div>
    </section>
    <!--メンテナンス体制-->
    <section class="reinfo_blog battery_v1 little-padding-bottom">
      <div class="container text-center">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <h4 data-aos="fade-right" style="margin-top:15px;" :data-aos-duration="500" >
                 <img src="/static/images/pylontech.jpg" style="width:275px"/> <br/>
                REイニシアティブ株式会社はPylontech正規代理店
                <br/>
                <br/>
              </h4>
            </div>
          </div>
          <div class="col-lg-6" style="text-align:right">
            <img src="/static/images/pylontech-1.png" style="width:100%"/>
          </div>
          <div class="col-lg-6" style="text-align:left;font-size:14px;line-height:40px;">
               <h6 :data-aos-duration="500" data-aos="fade-right" style="color:#7a808d;">
                 <h4 style="line-height:40px;font-size:16px;">Pylontechは、</h4>
                 電力貯蔵システム 専門のサプライヤーとして、
                 <br/>電気化学、電力事業、システムインテグレーションに
                 <br>おける専門知識を強化することにより、
                 <br/>信頼性の高い安価なバッテリー製品とソリューションを、
                 <br/>グローバルマーケットに提供することができました。
                </h6>
                 <h6 :data-aos-duration="500" data-aos="fade-right" style="padding: 15px;">
                    <div class="pylontech-pdf">
                      <a href="/static/pdf/Pylontech.pdf" target="_blank">Pylontech社の案内 ダウンロード </a>
                    </div>
                 </h6>
          </div>
        </div>

      </div>
    </section>
    <!--PDF View-->
    <section class="reinfo_blog battery_v1 battery-padding-bottom_v1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
              <h3>
                <i aria-hidden="true" class="fa fa-file-text"></i>
                蓄電システムパンフレット
              </h3>
              <h4 :data-aos-duration="500" data-aos="fade-right">
                Storage Battery System Pamphlet
              </h4>
            </div>
          </div>
        </div>
        <!--PDF View-->
        <div :data-aos-duration="500" class="row align-items-center pdf-View" data-aos="fade-right">
          <iframe :src="src" frameborder="0" height="100%" width="100%"></iframe>
        </div>
      </div>
    </section>
    <!--メンテナンス体制-->
    <section class="reinfo_blog battery_v1 little-padding-bottom">
      <div class="container text-center">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <h3 data-aos="fade-right" style="margin-top:15px;" :data-aos-duration="500" >
                <i aria-hidden="true" class="fa fa-tools"></i>
                REI-AI storage battery system 管理体制
              </h3>
              <h5 :data-aos-duration="500" data-aos="fade-right" style="margin-top:15px;">
                ANDPADシステムを利用し受注契約から施工・メンテナンスまでを一元管理
              </h5>
              <div style="width:100%;height:auto">
                <a href="https://lp.andpad.jp/" target="_blank" :data-aos-duration="500" data-aos="fade-right">
                  <img :data-aos-duration="500" class="ap_logo" data-aos="fade-right"
                       src="/static/images/ap_logo_h.jpg"/>
                </a>
                <h6 :data-aos-duration="500" data-aos="fade-right" style="color:#7a808d;font-size:14px;line-height:30px;">
                  営業から施工担当者まで必要な情報をだけを共有し、<br/>
                  各部署がスムーズに対応できるよう安全確実な管理体制を整えております。
                </h6>
                <img :data-aos-duration="500" class="management-img" data-aos="fade-right"
                    src="/static/images/management.png"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- End reinfo_feature -->
  </div>
</template>

<script>
const titles = ['項目', '内容', '備考'];
// REI- AI storage battery System-200K
const columns_200k = [
  {item: '蓄電容量', text: '200kWh', memo: '（214kWh）'},
  {item: '寸法（㎜）', text: 'W:1450×D:1100×H:2200×２', memo: '2箱'},
  {item: '重　量', text: '2.1t＋1.8t　合計3.9t', memo: ''},
  {item: '系統PCS', text: '200V　50/60Hz　３相３線　60kW', memo: '出力制限　可'},
  {item: '停電時出力', text: '最大60kW　', memo: '自動切換　オプション'},
  {item: '太陽光MPPT', text: 'DC/DC　60kW', memo: '直流のまま充電'},
  {item: '動作温度', text: '-20～60℃', memo: 'エアコン'},
  {item: '表　示', text: '10インチタッチパネル', memo: ''},
  {item: 'AI学習', text: '有', memo: ''},
  {item: '太陽光発電予測', text: '有', memo: ''},
  {item: '設　置', text: '屋内、屋外', memo: '塩害塗装標準'},
  {item: '火災検知装置', text: '煙、温度、ガス検出', memo: ''},
  {item: '消火設備', text: 'ヘプタフルオロプロパン', memo: 'オプション'},
  {item: '他装置連携', text: 'ディーゼル発電', memo: 'オプション'},
  {item: 'サイクル数', text: '7300サイクル', memo: '-'},
  {item: 'DOD', text: '80%', memo: '-'}
];
// REI- AI storage battery System-100K
const columns_100k = [
  {item: '蓄電容量', text: '100kWh', memo: '（107kWh）'},
  {item: '寸法（㎜）', text: 'W:1450×D:1100×H:2200', memo: ''},
  {item: '重　量', text: '2.1t', memo: ''},
  {item: '系統PCS', text: '200V　50/60Hz　３相３線　60kW', memo: '出力制限　可'},
  {item: '停電時出力', text: '最大60kW　', memo: '自動切換　オプション'},
  {item: '太陽光MPPT', text: 'DC/DC　60kW', memo: 'オプション'},
  {item: '動作温度', text: '-20～60℃', memo: 'エアコン'},
  {item: '表　示', text: '10インチタッチパネル', memo: ''},
  {item: 'AI学習', text: '有', memo: ''},
  {item: '太陽光発電予測', text: '有', memo: 'オプション '},
  {item: '設　置', text: '屋内、屋外', memo: '塩害塗装標準'},
  {item: '火災検知装置', text: '煙、温度、ガス検出', memo: ''},
  {item: '消火設備', text: 'ヘプタフルオロプロパン', memo: 'オプション'},
  {item: '他装置連携', text: 'ディーゼル発電', memo: 'オプション'},
  {item: 'サイクル数', text: '7300サイクル', memo: '-'},
  {item: 'DOD', text: '80%', memo: '-'}
];

// REI- AI storage battery System-50kw
const columns_50k = [
  {item: '蓄電容量', text: 'W:1450×D:1100×H:2200', memo: ''},
  {item: '重　    量', text: '1.5t', memo: ''},
  {item: '系統PCS', text: '200V　50/60Hz　３相３線　30kW', memo: '出力制限　可'},
  {item: '停電時出力', text: '最大30kW　', memo: 'オプション自動切換　'},
  {item: '太陽光MPPT', text: 'DC/DC　30kW', memo: ''},
  {item: '動作温度', text: '-20～60℃', memo: 'エアコン'},
  {item: '表　    示', text: '10インチタッチパネル', memo: ''},
  {item: 'AI学習', text: '有', memo: ''},
  {item: '太陽光発電予測', text: '有', memo: ''},
  {item: '設　    置', text: '屋内・屋外', memo: '塩害塗装標準'},
  {item: '火災検知装置', text: '煙、温度、ガス検出', memo: ''},
  {item: '消火設備', text: 'ヘプタフルオロプロパン', memo: 'オプション'},
  {item: '他装置/ 連携', text: 'ディーゼル発電', memo: 'オプション'},
  {item: 'サイクル数保証', text: '7300サイクル', memo: ''},
  {item: 'DOD', text: '80%', memo: ''}
];
// メンテナンス体制
const maintenances = [
  {
    href: '/static/pdf/battery-word.pdf',
    src: '/static/images/portfolio_1.jpg',
    text: '保守契約制度 ZMP'
  },
  {
    href: '/static/pdf/battery-word.pdf',
    src: '/static/images/portfolio_2.jpg',
    text: '24時間バックアップ体制'
  },
  {
    href: '/static/pdf/battery-word.pdf',
    src: '/static/images/portfolio_3.jpg',
    text: '人財育成'
  }
]
import Table_el from "../components/Table";
import Battery_el from "../components/Battery100k";

export default {
  name: "Battery",
  components: {Table_el, Battery_el},
  data() {
    return {
      viewerUrl: '/static/pdfVeiw/web/viewer.html',
      fileUrl: '/static/pdf/battery-word.pdf',
      columns_100k,
      columns_200k,
      columns_50k,
      titles,
      maintenances
    }
  },
  computed: {
    src() {
      return `${this.viewerUrl}?file=${encodeURIComponent(this.fileUrl)}&zoom=page-width`
    }
  }
}
</script>
