<template>
  <!-- Start reinfo_pricing section -->
  <section class="reinfo_pricing　 service_v1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_title text-center" data-aos="fade-right" :data-aos-duration="500">
            <h3>
              <i class="fa fa-leaf icon-hidde_v1"></i>
              Service
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="pircing_box">
            <div class="icon" data-aos="fade-right" :data-aos-duration="500">
              <img src="/static-en/images/icon_4.png" class="img-fluid" alt="">
            </div>
            <div class="title" data-aos="fade-right" :data-aos-duration="500">
              <h3>Storage Battery system</h3>
            </div>
            <div class="list" data-aos="fade-right" :data-aos-duration="500">
              <ul>
                <li class="service_li">
                 The storage battery system is indispensable for the efficient use of unstable natural energy. What a storage battery system requires is low cost, safety and durability. In order to achieve all the requirements at a satisfactory level, The RE initiative’s Industrial Power System is equipped with our unique AI and comply the maximum cost performance in operation with the most appropriate system.
                 </li>
              </ul>
            </div>
            <div class="button_box" data-aos="fade-right" :data-aos-duration="500">
              <router-link to="/service/Battery-en" class="reinfo_btn">More</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="pircing_box">
            <div class="icon" data-aos="fade-right" :data-aos-duration="500">
              <img alt="" class="img-fluid" src="/static-en/images/icon_5.png">
            </div>
            <div class="title" data-aos="fade-right" :data-aos-duration="500">
              <h3>Wind Power Generation</h3>
            </div>
            <div class="list" data-aos="fade-right" :data-aos-duration="500">
              <ul>
                <li class="service_li">
                 Wind power is the most utilized natural energy in the world. In Japan, it is installed in coastal areas where a certain stable wind speed can be obtained. However, an environment-friendly method which can obtain more wind power in offshore and mountain ridge areas is in needed.
                </li>
              </ul>
            </div>
            <div class="button_box" data-aos="fade-right" :data-aos-duration="500">
              <router-link to="/service/Wind-en" class="reinfo_btn">More</router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="pircing_box">
            <div class="icon" data-aos="fade-right" :data-aos-duration="500">
              <img alt="" class="img-fluid" src="/static-en/images/icon_6.png">
            </div>
            <div class="title" data-aos="fade-right" :data-aos-duration="500">
              <h3 style="min-height:82px">Solar Power</h3>
            </div>
            <div class="list" data-aos="fade-right" :data-aos-duration="500">
              <ul>
                <li class="service_li">
                  Though the overseas products have become the mainstream of solar power generation systems, Solar power is founded by Japanese research and technological development. It uses solar power to generate electricity, and is currently being used as a renewable energy with better cost performance compared to overseas products. The facts of limited electricity at night and impossibility to generate electricity in rainy or snowy weather cause the difference between supply and demand.
                </li>
              </ul>
            </div>
            <div class="button_box" data-aos="fade-right" :data-aos-duration="500">
              <router-link to="/service/Solar-en" class="reinfo_btn">More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End reinfo_pricing section -->
</template>

<script>
export default {
  name: "ServiceMain"
}
</script>
