<template>
  <div :data-aos-duration="500" class="agency-style" data-aos="fade-right">
    当社では工事業者様を募集しています。<br/>
    詳細についてはお問い合わせページからご連絡ください。
  </div>
</template>

<script>
export default {
  name: "Contractor"
}
</script>