<template>
  <!-- Start service_feature -->
  <section class="reinfo_service service_v1 all-page-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h3 data-aos="fade-right" :data-aos-duration="500">
              <i class="fa fa-leaf icon-hidde_v1"></i>
              Wind-Power Generation
            </h3>
          </div>
        </div>
      </div>
      <div class="row" style="height:400px">
      </div>
    </div>
  </section>
  <!-- End reinfo_feature -->
</template>

<script>
export default {
  name: "Wind"
}
</script>
