<template>
  <div :data-aos-duration="500" class="agency-style" data-aos="fade-right">
    This is an agency-only page.<br/>
    If you would like an agency contract, please contact us from the inquiry page.
  </div>
</template>

<script>
export default {
  name: "Agency"
}
</script>