<template>
  <div class="re_contact_body common_body">
    <div>
      <div class="re_contact_step_body" data-aos="fade-right">
        <el-steps :active="step" simple v-if="ww>850">
          <el-step title="Enter the information" icon="el-icon-edit"></el-step>
          <el-step title="Confirmation contents" icon="el-icon-tickets"></el-step>
          <el-step title="Transmission completed" icon="el-icon-circle-check"></el-step>
        </el-steps>
        <el-steps :active="step" simple v-else>
          <el-step title="Input" icon="el-icon-edit"></el-step>
          <el-step title="Confirm" icon="el-icon-tickets"></el-step>
          <el-step title="Completed" icon="el-icon-circle-check"></el-step>
        </el-steps>
      </div>
      <div>
        <div class="re_contact_form_body" v-show="step===0">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" :label-position="ww>850?'left':'top'">
            <el-form-item label="Inquiry Type" prop="typeContact" data-aos="fade-right" :data-aos-duration="500">
              <el-radio-group v-model="ruleForm.typeContact">
                <el-radio v-for="(item,index) in radioList()" :key="index" :label="index">{{ item.called }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Name" prop="name">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.name"
                        placeholder="(Example) RE Taro"></el-input>
            </el-form-item>
            <el-form-item label="Name Furigana" prop="nameFurigana">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.nameFurigana"
                        placeholder="(Example) RE Taro"></el-input>
            </el-form-item>
            <el-form-item label="Company Name" prop="companyName">
              <el-input v-model="ruleForm.companyName" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(Example) RE Initiative Co., Ltd."></el-input>
            </el-form-item>
            <el-form-item label="Department" prop="departmentName">
              <el-input v-model="ruleForm.departmentName" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(Example) Section chief"></el-input>
            </el-form-item>
            <el-form-item label="Position" prop="positionName">
              <el-input v-model="ruleForm.positionName" :data-aos-duration="500" data-aos="fade-right"
                        placeholder="(Example) Manager"></el-input>
            </el-form-item>
            <el-form-item label="Address（Prefecture）" prop="address">
                <el-select v-model="ruleForm.address" :data-aos-duration="500" data-aos="fade-right"
                           placeholder="Select the prefecture">
                  <el-option
                      v-for="(value, key, index) in options"
                      :key="index"
                      :label="value"
                      :value="value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="Phone Number" class="is-required">
              <div class="re_contact_form_3" data-aos="fade-right" :data-aos-duration="500">
                <el-form-item prop="phone" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone" @input="checkNumber('phone')" :maxlength="4"
                            placeholder="(Example) 03"></el-input>
                </el-form-item>
                <span class="re_contact_form_-">-</span>
                <el-form-item prop="phone2" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone2" @input="checkNumber('phone2')" :maxlength="4"
                            placeholder="(Example) 6402"></el-input>
                </el-form-item>
                <span class="re_contact_form_-">-</span>
                <el-form-item prop="phone3" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.phone3" @input="checkNumber('phone3')" :maxlength="4"
                            placeholder="(Example) 3341"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="Fax Number" id="fax" prop="fax">
              <div class="re_contact_form_3" data-aos="fade-right" :data-aos-duration="500">
                <el-form-item prop="fax" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax" @input="checkNumber('fax')" :maxlength="4"
                            placeholder="(Example) 03"></el-input>
                </el-form-item>
                <span class="re_contact_form_-">-</span>
                <el-form-item prop="fax2" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax2" @input="checkNumber('fax2')" :maxlength="4"
                            placeholder="(Example) 6402"></el-input>
                </el-form-item>
                <span class="re_contact_form_-">-</span>
                <el-form-item prop="fax3" class="phone_input_item">
                  <el-input type="tel" v-model="ruleForm.fax3" @input="checkNumber('fax3')" :maxlength="4"
                            placeholder="(Example) 3341"></el-input>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="E-mail" prop="email">
              <el-input data-aos="fade-right" :data-aos-duration="500" v-model="ruleForm.email" placeholder="(Example) reinfo@reinfo.info"></el-input>
            </el-form-item>
            <el-form-item label="Contents" prop="content">
              <el-input data-aos="fade-right" :data-aos-duration="500" type="textarea" v-model="ruleForm.content" placeholder="Please feel free to fill in inquiries, consultation details, etc." :rows="6"></el-input>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="re_contact_form_agree_content_title">Handling of personal information provided
              </div>
              <el-collapse-transition data-aos="fade-right" :data-aos-duration="500">
                <div>
                  <div class="re_contact_form_agree_content">
                    <h5>Purpose of using personal information</h5>
                    <p>To accurately understand and deal with inquiries and requests for materials.</p>
                    <br />
                    <h5>Providing personal information to a third party</h5>
                    <p>We will not provide the acquired personal information to a third party except with the consent of the person or in accordance with laws and regulations.</p>
                    <br />
                    <h5>Consignment</h5>
                    <p>We may outsource all or part of the handling of personal information within the scope of achieving the above purpose of use. When outsourcing, we will select a person who meets the sufficient protection level of personal information, and perform necessary and appropriate supervision of the outsourced person.</p>
                    <br />
                    <h5>Disclosure of personal information subject to disclosure and contact point for inquiries</h5>
                    <p>We accept requests for disclosure, etc. regarding this personal information (notification of purpose of use, disclosure, correction / addition or deletion, suspension of use or provision). For the procedure, please contact the personal information complaint and consultation counter. However, if it is based on laws and regulations, we may not be able to respond to disclosure. 
Please note.
</p>
                    <br />
                    <h5>Notes on entering personal information</h5>
                    <p>Providing information other than the required items is optional. However, if such information is not provided, we may not be able to respond appropriately to your question.</p>
                    <br />
                    <h5>Acquisition of personal information by a method that the person cannot easily recognize></h5>
                    <p>This website uses some cookies in order to provide the best service to our customers. We do not obtain any personally identifiable information from this cookie.</p>
                    <br />
                    <h5>Inquiries regarding the handling of personal information</h5>
                    <p>RE Initiative Co., Ltd.</p>
                    <p>To the personal information protection manager</p>
                    <p>Tel：{{HomeInfo_EN.Tel}}</p>
                  </div>
                </div>
              </el-collapse-transition>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc is-required" data-aos="fade-right" :data-aos-duration="500">
              <el-checkbox v-model="ruleForm.agree" v-if="ww>850">
                I agree with the handling of personal information
              </el-checkbox>
              <el-checkbox v-model="ruleForm.agree" v-else>
                I agree
              </el-checkbox>
            </el-form-item>
            <el-form-item label-width="0px" prop="type" class="tc" data-aos="fade-right" :data-aos-duration="500">
              <div class="tc">
                <input type="button" class="reinfo_btn" @click="submitForm('ruleForm')" value=" Check your entries " />
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-show="step===1" class="re_contact_form_check_body">
          <table class="re_contact_form_check_table">
            <tbody>
             <tr data-aos="fade-right" :data-aos-duration="500">
              <td class="re_contact_form_check_title">Inquiry Type</td>
               <td class="re_contact_form_check_content">{{ radioList()[ruleForm.typeContact].called }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="re_contact_form_check_title">Name</td>
               <td class="re_contact_form_check_content">{{ ruleForm.name }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="re_contact_form_check_title">Name Furigana</td>
               <td class="re_contact_form_check_content">{{ ruleForm.nameFurigana }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.companyName!=''">
               <td class="re_contact_form_check_title">Company Name</td>
               <td class="re_contact_form_check_content">{{ ruleForm.companyName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.departmentName!=''">
               <td class="re_contact_form_check_title">Department</td>
               <td class="re_contact_form_check_content">{{ ruleForm.departmentName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.positionName!=''">
               <td class="re_contact_form_check_title">Position</td>
               <td class="re_contact_form_check_content">{{ ruleForm.positionName }}</td>
             </tr>
             <tr :data-aos-duration="500" data-aos="fade-right" v-if="ruleForm.address!=''">
               <td class="re_contact_form_check_title">Address (Prefecture) </td>
               <td class="re_contact_form_check_content">{{ ruleForm.address }}</td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="re_contact_form_check_title">Phone Number </td>
               <td class="re_contact_form_check_content">
                 {{ ruleForm.phone + '-' + ruleForm.phone2 + '-' + ruleForm.phone3 }}
               </td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500" v-if="ruleForm.fax!=''">
               <td class="re_contact_form_check_title">Fax Number</td>
               <td class="re_contact_form_check_content">
                 {{ ruleForm.fax + '-' + ruleForm.fax2 + '-' + ruleForm.fax3 }}
               </td>
             </tr>
             <tr data-aos="fade-right" :data-aos-duration="500">
               <td class="re_contact_form_check_title">E-mail</td>
              <td class="re_contact_form_check_content">{{ruleForm.email}}</td>
            </tr>
            <tr data-aos="fade-right" :data-aos-duration="500">
              <td class="re_contact_form_check_title">Contents</td>
              <td class="re_contact_form_check_content type_content">{{ruleForm.content}}</td>
            </tr>
            </tbody>
          </table>
          <div class="tc" data-aos="fade-right" :data-aos-duration="500">
             <input type="button" class="reinfo_btn_v1" @click="sendMail()" :loading="sending" value="Send Message" />
             <input type="button" class="reinfo_back" @click="backform()" value="Back" />
          </div>
        </div>
        <div v-show="step===2" class="re_contact_form_send_ok_body tc">
          <i class="el-icon-success re_contact_form_send_ok_ico"></i>
          <br />
          <p class="f18" data-aos="fade-right" :data-aos-duration="500">Thank you for your inquiry.<br />The transmission was completed successfully.</p>
          <div class="tc mt30" data-aos="fade-right" :data-aos-duration="500">
            <input type="button" class="reinfo_btn" @click="backtop()" value=" Go back to the top page " />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const typelist = [{called:'Power storage system'},{called:'Windmill'},{called:'Solar'},{called:'About agents and dealers'},{called:'About the contractor'},{called:'Others'}];
const options = {
  '41043':'Hokkaido',
  '41045':'Aomori Prefecture',
  '41047':'Iwate Prefecture',
  '41049':'Miyagi Prefecture',
  '41051':'Akita Prefecture',
  '41053':'Yamagata Prefecture',
  '41055':'Fukushima Prefecture',
  '41057':'Ibaraki Prefecture',
  '41059':'Tochigi Prefecture',
  '41061':'Gunma Prefecture',
  '41063':'Saitama Prefecture',
  '41065':'Chiba Prefecture',
  '41067':'Tokyo',
  '41069':'Kanagawa Prefecture',
  '41071':'Niigata Prefecture',
  '41073':'Toyama Prefecture',
  '41075':'Ishikawa Prefecture',
  '41077':'Fukui Prefecture',
  '41079':'Yamanashi Prefecture',
  '41081':'Nagano Prefecture',
  '41083':'Gifu Prefecture',
  '41085':'Shizuoka Prefecture',
  '41087':'Aichi Prefecture',
  '41089':'Mie Prefecture',
  '41091':'Shiga Prefecture',
  '41093':'Kyoto Prefecture',
  '41095':'Osaka',
  '41097':'Hyogo Prefecture',
  '41099':'Nara Prefecture',
  '41101':'Wakayama Prefecture',
  '41103':'Tottori prefecture',
  '41105':'Shimane Prefecture',
  '41107':'Okayama Prefecture',
  '41109':'Hiroshima Prefecture',
  '41111':'Yamaguchi Prefecture',
  '41113':'Tokushima Prefecture',
  '41115':'Kagawa Prefecture',
  '41117':'Ehime Prefecture',
  '41119':'Kochi Prefecture',
  '41121':'Fukuoka Prefecture',
  '41123':'Saga Prefecture',
  '41125':'Nagasaki Prefecture',
  '41127':'Kumamoto Prefecture',
  '41129':'Oita Prefecture',
  '41131':'Miyazaki Prefecture',
  '41133':'Kagoshima Prefecture',
  '41135':'Okinawa Prefecture',
  '41137':'Overseas'
}

export default {
  name: "MailFrom",
  data() {
    return {
      step: 0,
      agree: false,
      options,
      ww: window.innerWidth,
      ruleForm: {
        typeContact: 0,
        name: '',
        nameFurigana: '',
        companyName: '',
        departmentName: '',
        positionName: '',
        address:'',
        phone: '',
        phone2: '',
        phone3: '',
        fax: '',
        fax2: '',
        fax3: '',
        email: '',
        content: '',
        agree: false
      },
      sending: false,
      rules: {
        name: [
          {
            required: true,
            message: 'Please enter your name.',
            trigger: 'blur'
          }
        ],
        nameFurigana: [
          {
            required: true,
            message: 'Please enter the frigana.',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        phone2: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: true,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        phone3: [
          {
            pattern: /^[0-9]{4}$/g,
            required: true,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        fax: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: false,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        fax2: [
          {
            pattern: /^[0-9]{2,4}$/g,
            required: false,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        fax3: [
          {
            pattern: /^[0-9]{4}$/g,
            required: false,
            message: 'Please enter the number.',
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter your e-mail address correctly.',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: 'Please enter your inquiry correctly',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
  },
  methods: {
    radioList() {
       return typelist;
    },
    checkNumber(name) {
      let text = this.ruleForm[name]
      this.ruleForm[name] = text.replace(/[^\d]/g, '')
    },
    backtop() {
      window.location.href = '/'
    },
    send() {
      this.step = 2
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    backform() {
      this.step = 0
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.ruleForm.agree) {
            this.$message({
              message:
                  'Please check "I agree to the handling of personal information".',
              type: 'warning'
            })
            return false
          }
          this.step = 1
          document.body.scrollTop = document.documentElement.scrollTop = 0
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    sendMail() {
      let params = {
        name: this.ruleForm.name,
        nameFurigana: this.ruleForm.nameFurigana,
        companyName: this.ruleForm.companyName,
        departmentName: this.ruleForm.departmentName,
        positionName:this.ruleForm.positionName,
        address:this.ruleForm.address,
        phone: this.ruleForm.phone + this.ruleForm.phone2 + this.ruleForm.phone3,
        fax: this.ruleForm.fax + this.ruleForm.fax2 + this.ruleForm.fax3,
        email: this.ruleForm.email,
        content: this.ruleForm.content,
        typeContact: typelist[this.ruleForm.typeContact].called
      }
      this.sending = true,
          $.ajax({
            type: "POST",
            url: "/api/mail_en.php",
            dataType: "json",
            data: params,
            success: (res) => {
              this.sending = false
              if (!res) {
                this.$message({
                  message:
                      'Under maintenance now.We apologize for the inconvenience. Thank you for your understanding. ',
                  type: 'warning'
                })
                return false
              }
              if (res.code === 1) {
                this.send()
              } else {
                this.$message({
                  message:
                      'Under maintenance now.We apologize for the inconvenience. Thank you for your understanding. ',
                  type: 'warning'
                })
              }
            },
          });
    }
  }
}
</script>
<style scoped>
@import '/static-en/css/style.css"';
</style>
