<template>
  <header class="header_area header_v1 transparent_header  all-page-padding">
    <div class="container">
      <div class="site_menu">
        <div class="row align-items-center">
          <div class="col-lg-2">
            <div class="brand">
              <router-link to="/" class="logo"><img src="/static/images/logo_1.png" class="img-fluid" alt="">
              </router-link>
            </div>
          </div>
          <div class="col-lg-7 nowrap">
            <div class="primary_menu" id="menu">
              <nav class="main_menu">
                <ul>
                  <li :class="{'active_link':pathType==='home'}" class="menu-item">
                    <router-link to="/" @click="$root.reload">ホーム</router-link>
                  </li>
                  <li :class="{'active_link':pathType==='about'}" class="menu-item">
                    <router-link to="/about" @click="$root.reload">会社概要</router-link>
                  </li>
                  <li :class="{'active_link':(pathType === 'serviceMain')}" class="menu-item">
                    <router-link to="/service" @click="$root.reload">事業内容</router-link>
                    <ul class="sub-menu">
                      <li class="menu-item" v-for="(item,index) in subList" :key="index">
                        <router-link :to="`${item.link}`" @click="$root.reload">{{ item.name }}</router-link>
                      </li>
                    </ul>
                  </li>
                  <li :class="{'active_link':pathType==='access'}" class="menu-item"><router-link to="/access" @click="$root.reload">アクセス</router-link></li>
                  <li :class="{'active_link':pathType==='contact'}" class="menu-item"><router-link to="/contact" @click="$root.reload">お問い合わせ</router-link></li>
                  <li><a href="/en" style="background-image: url(/static-en/images/en.svg);
                            background-repeat: no-repeat;
                            background-position: left;
                            padding-left: 30px;">English</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile_wrapper">
        <div class="mobile_header">
          <div class="row align-items-center">
            <div class="col-6">
              <div class="brand_logo">
                <router-link to="/"><img src="/static/images/logo_1.png" class="img-fluid" alt=""></router-link>
              </div>
            </div>
            <div class="col-6">
              <div class="menu_button">
                <div class="menu_icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sidenav_menu" id="sidenav_menu">
          <div class="close_icon">
            <router-link to="#" class="close_btn"><i class="fas fa-times"></i></router-link>
          </div>
          <ul class="sidebar-menu" id="sidebar-menu">
            <li class="menu-item" :class="{'active':pathType==='home'}"><router-link to="/" @click="closeSidenavMenu(true);$root.reload()">ホーム</router-link></li>
            <li class="menu-item" :class="{'active':pathType==='about'}"><router-link to="/about" @click="closeSidenavMenu(true);$root.reload()">会社概要</router-link>
            </li>
            <li class="menu-item" :class="{'active':pathType==='serviceMain'}"><router-link to="/service">事業内容</router-link>
              <ul class="sub-menu" id="sub-menu">
                <li class="menu-item" v-for="(item,index) in subList" :key="index" :class="{'active':pathType === item.key}"><router-link :to="`${item.link}`" @click="closeSidenavMenu(false);$root.reload()">{{item.name}}</router-link></li>
              </ul>
            </li>
            <li class="menu-item" :class="{'active':pathType==='access'}"><router-link to="/access" @click="closeSidenavMenu(true);$root.reload()">アクセス</router-link></li>
            <li class="menu-item" :class="{'active':pathType==='contact'}"><router-link to="/contact" @click="closeSidenavMenu(true);$root.reload()">お問い合わせ</router-link></li>
            <li><a href="/en" style="background-image: url(/static-en/images/en.svg);
                            background-repeat: no-repeat;
                            background-position: left;
                            padding-left: 30px;">English</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { onMounted,ref,watch } from '@vue/runtime-core'
import { useRoute} from "vue-router"
export default {
  name: "Header",
  setup() {
    const route = useRoute();
    const pathType = ref('')
    const subList = ref([
      {name: '蓄電システム', link: '/service/battery', key: 'battery'},
      {name: '風力発電', link: '/service/wind', key: 'wind'},
      {name: '太陽光発電', link: '/service/solar', key: 'solar'},
    ])

    const init = ()=>{
      $(window).on('scroll',function() {
          var scroll = $(window).scrollTop();
          if (scroll < 100) {
            $(".header_area").removeClass("sticky");
          }else{
            $(".header_area").addClass("sticky");
          }
      });
      $(".menu_icon,.close_btn").on('click', function (e) {
        e.preventDefault();
        $(".menu_icon").toggleClass("active");
      });
      $(".menu_icon,.close_btn").on('click', function (e) {
        e.preventDefault();
         $(".sidenav_menu").toggleClass("active");
      });
      $(".sidenav_menu").mouseleave(function(){
          $(".sidenav_menu").removeClass("active");
      });
      $.sidebarMenu($('.sidebar-menu'))
    }
    watch(
      () => route.meta.pathType,
      newPathType => {
        pathType.value = ['battery','wind','solar'].includes(newPathType) ? 'serviceMain' :newPathType;
      }
    )
    onMounted(()=>{
      init()
      pathType.value = route.meta.pathType;
    })

    function closeSidenavMenu (key){
      $(".sidenav_menu").removeClass("active");
      $(".sub-menu").removeClass("menu-open");
      if(key){
        $("#sub-menu").css("display","none");
      }
    }

    return {pathType,subList,closeSidenavMenu}
  }
}
</script>

<style>

</style>
