<template>

  <!-- Start reinfo_service section -->
  <section class="reinfo_service service_v1 reinfo_pricing all-page-padding">
    <div class="shape_v2">
      <img src="/static-en/images/shape/shape_7.png" class="shape_7" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
            <h3><i class="fa fa-sun icon-hidde_v1"></i>
              Solar Power</h3>
          </div>
        </div>
      </div>
      <div >
          <div class="row">
            <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
              <h3 class="title-h3">
                Did you know that installing solar panels on the roof will interfere <br/>with firefighting activities in the event of a fire?
                </h3>
                <h4 class="title-h4">
                  Fire and Disaster Management Agency responds to electric shock prevention<br/> for solar power generation equipment during firefighting activities.
                </h4>
            </div>
            </div>
          </div>
          <!--img-->
          <div class="row solar pc-hide">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static-en/images/num1.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static-en/images/num1-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static-en/images/num2.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static-en/images/num2-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <img src="/static-en/images/num3.png" class="solar-numberlist">
              <div class="pircing_box">
                <div class="icon" data-aos="fade-right" :data-aos-duration="500">
                  <img src="/static-en/images/num3-icon.png" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div class="row solar mobile-show">
            <div class="col-lg-4 col-md-6 col-sm-12"  style="display:block">
               <img src="/static-en/images/optimizer-title-0.png" style="width:100%" data-aos="fade-right" :data-aos-duration="500"/>
            </div>
          </div>
          <!--word-->
          <div class="row solar solar-word text-center" style="display:block">
            <img src="/static-en/images/optimizer0.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
          </div>
      </div>
      <!--オプティマイザーの重要性-->
      <div class="container" style="margin-top:30px"> 
        <div class="row optimizer pc-hide">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="text-center " data-aos="fade-right">
              <h4 class="title-h4-text" data-aos="fade-right" :data-aos-duration="500" >
                  <img src="/static-en/images/title-1.png" class="img-fluid"/>
                  <h3 data-aos="fade-right" :data-aos-duration="500" class="title-h4-text">
                    Importance of Optimizer
                  </h3>
                  You can safely cut off the output <span class="h3-line">from the solar panel</span>
              </h4>
            </div>
          </div>
        </div>
        <div class="row optimizer mobile-show">
            <div class="col-lg-12">
              <div :data-aos-duration="500" class="text-center " data-aos="fade-right">
                 <img src="/static-en/images/optimizer-title-1.png" style="width:95%"/>
              </div>
            </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static-en/images/optimizer.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
      <!--高効率に調節できる-->
      <div class="container" style="margin-top:30px">
        <div class="row">
          <div class="col-lg-12 pc-hide">
            <div :data-aos-duration="500" class="text-center" data-aos="fade-right">
              <h4 class="title-h4-text"><img src="/static-en/images/title-2.png" class="img-fluid" />
                The voltage of each solar panel can be <span class="h3-line">adjusted with high efficiency</span>
              </h4> 
            </div>
          </div>
          <div class="col-lg-12 mobile-show">
             <img src="/static-en/images/optimizer-title-2.png" style="width:95%"/>
          </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static-en/images/optimizer1.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
      <div class="container" style="margin-top:30px">
        <div class="row">
          <div class="col-lg-12 pc-hide">
            <div :data-aos-duration="500" class="text-center" data-aos="fade-right">
              <h4 class="title-h4-text"><img src="/static-en/images/title-3.png" class="img-fluid" />
                Existing equipment such as solar panels and power <br/> conditioners can be used as they are
              </h4> 
            </div>
          </div>
          <div class="col-lg-12 mobile-show">
             <img src="/static-en/images/optimizer-title-3.png" style="width:100%"/>
          </div>
        </div>
      </div>
      <div class="row solar solar-word text-center" style="display:block">
          <img src="/static-en/images/optimizer2.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
      </div>
    </div>
     <div class="container" style="margin-top:30px">
      <div class="row">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">  
           <br/>
            <h3>Optimizer specifications</h3>
            <br/>
            <img src="/static-en/images/optimizer3.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
          </div>
        </div>
      </div>
      <div class="row" style="margin-top:25px;">
        <div class="col-lg-12">
          <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
            <h3>Cutting device specifications</h3>
            <br/>
            <img src="/static-en/images/optimizer4.png" style="width:90%" data-aos="fade-right" :data-aos-duration="500"/>
           </div>
        </div>
      </div>
     </div>
  </section>
  <!-- End reinfo_service section -->
</template>

<script>
export default {
  name: "Solar",
    data() {
      return {
        ww: window.innerWidth
      }
  }
}
</script>

<style scoped>

</style>
