<template>
  <!-- Start reinfo_banner section -->
  <section class="reinfo_banner banner_v1 banner_p">
    <div class="home_banner_container" id="banner_content">
      <div class="hero_slider">
        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                      :src="isMobile? '/static-en/images/banner_1_mobile.png' : '/static-en/images/banner_1.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20">
                    <span class="fontGrad-bg">2.5 months delivery time for storage battery system</span>
                    <br/>
                    <span class="cWhite textShadow">Storage Battery System</span>
                  </h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    The storage battery system is indispensable for the efficient use of unstable natural energy. What a storage battery system requires is low cost, safety and durability. In order to achieve all the requirements at a satisfactory level, The RE initiative’s Industrial Power System is equipped with our unique AI and comply the maximum cost performance in operation with the most appropriate system. The storage battery capacity can be expanded up to 1 MW which makes it ideal for building industrial storage battery system.
                  </p>
                  <router-link to="/Contact-en" class="reinfo_btn">Contact Us</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                     :src="isMobile? '/static-en/images/banner_2_mobile.png' : '/static-en/images/banner_2.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20"><span class="cWhite textShadow">Wind Power Generation</span></h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    Wind power storage battery system is the most utilized natural energy in the world. In Japan, it is installed in coastal areas where a certain stable wind speed can be obtained. However, an environment-friendly method which can obtain more wind power in offshore and mountain ridge areas is in needed. The RE initiative will research a wind-power generators with better performance and propose the installation plan sutable for Japan’s natural environment.
                  </p>
                  <router-link to="/Contact-en" class="reinfo_btn">Contact Us</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="signle_slider">
          <div class="row align-items-center_v1 no-lr">
            <div>
              <div class="banner_content_body">
                <div class="banner_content_img_body">
                  <img
                     :src="isMobile? '/static-en/images/banner_3_mobile.png' : '/static-en/images/banner_3.jpg'"
                  />
                </div>
                <div class="banner_content_text">
                  <h1 class="mb20"><span class="cWhite textShadow">Solar Power</span></h1>
                  <p class="banner_p mb20 cWhite textShadow">
                    Though the overseas products have become the mainstream of solar power generation systems, Solar energy is founded by Japanese research and technological development. It uses solar power to generate electricity, and is currently being used as a renewable energy with better cost performance compared to overseas products. The facts of limited electricity at night and impossibility to generate electricity in rainy or snowy weather cause the difference between supply and demand. With the RE initiative's storage battery system, it enables a stable supply of natural energy.
                  </p>
                  <router-link class="reinfo_btn" to="/Contact-en">Contact Us</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!--
  <div class="banner-hidden">
      <div class="banner-home-01"></div>
      <div class="banner-home-02"></div>
  </div>-->
  <!-- End reinfo_banner section-->

  <section class="reinfo_experience service_v1 all-page-padding">
    <div class="container  pc_show">
      <div class="row" data-aos="fade-right" :data-aos-duration="500">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-american-sign-language-interpreting icon-hidde_v1"></i>
              Company Philosophy
            </h3>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                We are striving to popularize the natural energy and keep on protecting the natural environment. What we can do is to do everything with our sincerity together with those who seek natural energy. Our company philosophy is to create a brighter future by connecting everyone in the world with a desire for a better world.
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>1</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Based on constant experiments and research</h4>
              <h5>Real-time data analysis</h5>
              <h6>We provide all-time service to our customers.</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div class="row align-items-center" data-aos="fade-right" :data-aos-duration="500">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Management philosophy</h4>
              <h5>For the future</h5>
              <h6>Looking forward to be a company trusted by the customers.</h6>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>2</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                We aim to be a vibrant group with experienced staff to improve. By conducting corporative activities based on our management philosophy, we continue to provide best product and stable services. The RE initiative are the leader in development of renewable energy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                While conducting and developing business based on our philosophy, we value transparency and manage a company that is trusted. We protect personal information, company information, etc., comply with laws and regulations, respect social ethical norms, conduct fair corporate activities, and have no relationship with antisocial forces. We, RE Initiative, will continue to rigorously propose excellent products to our customers.
              </p>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
              <h3>3</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Corporation Guidelines</h4>
              <h5>Honesty Sincerity Safety</h5>
              <h6>
                 We provide services that are trusted by our customers, as well as best products and cost performance.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container  mobile-show">
      <div :data-aos-duration="500" class="row" data-aos="fade-right">
        <div class="col-lg-12">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-american-sign-language-interpreting icon-hidde_v1"></i>
              Company Philosophy
            </h3>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
               <h3 style="font-size:35px!important">1</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Based on constant experiments and research</h4>
              <h5>Real-time data analysis</h5>
              <h6>We provide all-time service to our customers.</h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                We are striving to popularize the natural energy and keep on protecting the natural environment. What we can do is to do everything with our sincerity together with those who seek natural energy. Our company philosophy is to create a brighter future by connecting everyone in the world with a desire for a better world.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
               <h3 style="font-size:35px!important">2</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Management philosophy</h4>
              <h5>For the future</h5>
              <h6>Looking forward to be a company trusted by the customers.</h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                We aim to be a vibrant group with experienced staff to improve. By conducting corporative activities based on our management philosophy, we continue to provide best product and stable services. The RE initiative are the leader in development of renewable energy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="single_experience mobile-show">
        <div :data-aos-duration="500" class="row align-items-center" data-aos="fade-right">
          <div class="col-lg-2 col-md-2">
            <div class="icon text-center">
               <h3 style="font-size:35px!important">3</h3>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_info_box">
              <h4>Corporation Guidelines</h4>
              <h5>Honesty Sincerity Safety</h5>
              <h6>
                 We provide services that are trusted by our customers, as well as best products and cost performance.
              </h6>
            </div>
          </div>
          <div class="col-lg-5 col-md-5">
            <div class="reinfo_text_box">
              <p>
                While conducting and developing business based on our philosophy, we value transparency and manage a company that is trusted. We protect personal information, company information, etc., comply with laws and regulations, respect social ethical norms, conduct fair corporate activities, and have no relationship with antisocial forces. We, RE Initiative, will continue to rigorously propose excellent products to our customers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Start reinfo_about section -->
  <section class="reinfo_about about_v1  all-page-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <div class="reinfo_img_box wow fadeInLeft">
            <img src="/static-en/images/about.png" class="img-fluid" alt=""/>
          </div>
        </div>
        <div class="col-lg-7 item_width_100">
          <div class="reinfo_content_box wow fadeInRight">
            <h3 data-aos="fade-right" :data-aos-duration="500">
              <i class="fa fa-thumbs-up"></i>
              What we can do
            </h3>
            <p data-aos="fade-right" :data-aos-duration="500">
              In order to realize the carbon neutrality, the movement to control supply has led to tight demand and created a flow of soaring prices. The economic resumption after the COVID pandemic causes the increase of oil demand, power generation costs and a electric fee.
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              With the goal of carbon neutral ZERO in 2050, it is predicted that the electricity price will rise along with the rise in fuel price, and the market is expected that electricity will be sold at a high cost.
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              Our storage battery predicts the amount of solar power generation need in the next day based on meteorological data. Due to the bad weather and insufficient electric power, we will charge with late-night power, which has a lower electricity cost. This makes it possible to cut the peak power even during bad weather.
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              We will improve the accuracy by performing machine learning with AI on daily power consumption and power generation.
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              Customers' power usage varies greatly so that installation space, requests for measures against power outages is considered.
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              In order to meet various needs such as budget, it will be expensive for it will be designed exclusively for each customer. We will put storage batteries, storage battery power conditioners, solar power conditioners, and power outage inverters together to meet the needs of each customer at low cost.
            </p>
            <div class="btn_center" data-aos="fade-right" :data-aos-duration="500">
              <router-link class="reinfo_btn" to="/about-en">More</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End deneb_about section -->

  <!-- Start reinfo_blog section -->
  <!--
  <section class="reinfo_blog blog_v1  all-page-padding">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-12" data-aos="fade-right" :data-aos-duration="500">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-bell icon-hidde_v1"></i>
              お知らせ
            </h3>
            <h4><i class="fa fa-bell icon-hidde"></i>News</h4>
          </div>
        </div>
      </div>
      <div class="row" data-aos="fade-right" :data-aos-duration="500">
        <div id="news"
          class="col-lg-4 col-md-6 col-sm-12 news_list_item"
          v-for="(item, index) in newsList"
          :key="index"
        >
          <router-link :to="`/news-en/${item.id}`">
            <div class="grid_item wow fadeInUp">
              <div class="reinfo_img news_list_imggit">
                <img :src="item.img" class="img-fluid" />
              </div>
              <div class="reinfo_info">
                <span class="date">{{ item.time }}</span>
                <h3 class="news_list_title">
                  <span>{{ item.title }}</span>
                </h3>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <br />
      <router-link to="/news-en" class="reinfo_btn" data-aos="fade-right" :data-aos-duration="500">More</router-link>
    </div>
  </section>
  -->
  <!-- End reinfo_blog section -->
  <!-- Start reinfo_cta section -->
  <section class="reinfo_cta contact_v1  all-page-padding">
    <div class="container">
      <div class="cta_wrapper">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="cta_content" data-aos="fade-right" :data-aos-duration="500">
              <h3>If you need  consultations</h3>
              <p>
                Please feel free to contact us with any questions like product catalogs and orders.
              </p>
            </div>
          </div>
          <div class="col-lg-5" data-aos="fade-right" :data-aos-duration="500">
            <div class="button_box">
              <router-link to="/contact-en" class="reinfo_btn">Contact Us</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End reinfo_cta section -->
</template>


<script>
import { onMounted, ref } from "vue";
export default {
  name: "Home",
  setup() {
    const initSlick = () => {
      $(".hero_slider").slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        fade: true,
      });
    };
    const newsList = ref([]);
    const getNewsList = () => {
      $.ajax({
        type: "GET",
        url: "https://api.the.co.jp/api/news.php",
        dataType: "json",
        data: {
          page: 1,
        },
        success: (res) => {
          newsList.value = res.list.slice(0, 3);
        },
      });
    };
    onMounted(() => {
      initSlick();
      getNewsList();
    });
    return { newsList };
  },
  computed: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
};
</script>
