<template>
  <div :data-aos-duration="500" class="agency-style" data-aos="fade-right">
    We are looking for a contractor.<br/>
    Please contact us from the inquiry page for details.
  </div>
</template>

<script>
export default {
  name: "Contractor"
}
</script>