<template>
  <!-- Start reinfo_breadcrumb section -->
  <BreadCrumb ImageUrl="/static-en/images/breadcrumb_bg.jpg" PageName="About Us"/>
  <!-- End reinfo_breadcrumb section -->

  <section class="reinfo_achivement_V1 reinfo_experience section_padding  all-page-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12" data-aos="fade-right" :data-aos-duration="500">
          <div class="section_title text-center">
            <h3>
              <i class="fa fa-lightbulb icon-hidde_v1"></i>
              Topix
            </h3>
          </div>
        </div>
      </div>
      <div class="row align-items-center single_experience" data-aos="fade-right" :data-aos-duration="500">
        <div class="col-lg-12 col-md-12 single_experience">
          <div class="reinfo_text_box">
            <p>
              "REI- AI storage battery System" is the original brand of the RE initiative.<br/>
              Based on many years of research technology, we have developed new products with free ideas.<br/>
              We propose a new business style.<br/>
              Toward the achievement of the 2050 carbon neutral ZERO goal<br/>
              Electricity prices are expected to rise.<br/>
              As FIT prices continue to decline year by year, FIP has begun and the importance of electricity storage is increasing.<br/>
              In order to make effective use of renewable energy, we propose a power storage system under the RE initiative.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="reinfo_achivement reinfo_experience section_padding  all-page-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section_title text-center" data-aos="fade-right" :data-aos-duration="500">
            <h3>
              <i class="fa fa-building icon-hidde_v1"></i>
              Company
            </h3>
          </div>
        </div>
      </div>
      <div class="row align-items-center single_experience">
        <div class="col-lg-12 wow fadeInLeft company">
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i aria-hidden="true" class="fa fa-list-alt"></i>Company name</h4>
            <p>{{HomeInfo_EN.CompanyName}}</p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-user-circle" aria-hidden="true"></i>Representative</h4>
            <p>{{HomeInfo_EN.Representative}}</p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-building" aria-hidden="true"></i>Tokyo Office</h4>
            <p>
              Liveport Hamamatsucho 7F, <br/> 2-5-3 Hamamatsucho, Minato-ku,<br/> Tokyo 105-0013, Japan
              <br/> 
              <a :href="'tel:'+HomeInfo_EN.Tel">{{HomeInfo_EN.Tel}}</a>
            </p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-envelope-open" aria-hidden="true"></i>E-mail</h4>
            <p><a :href="'mailto:'+HomeInfo_EN.Email">{{HomeInfo_EN.Email}}</a></p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-jpy" aria-hidden="true"></i>capital</h4>
            <p>40 million yen</p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-calendar-check-o" aria-hidden="true"></i>Established</h4>
            <p>{{HomeInfo_EN.CreateDate}}</p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-users" aria-hidden="true"></i>Board member</h4>
            <p>
              Representative Director&emsp;&emsp;Hara Akio<br />
              Chairman of the Board &emsp;&emsp;Mitsuharu Hoshino<br />
              Executive Director&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;Kiyozo Doi
            </p>
          </div>
          <div class="reinfo_content_box line" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-share-alt-square" aria-hidden="true"></i>Business content</h4>
            <p>
              Renewable energy business<br />
              Environmental load reduction promotion business.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Start reinfo_about section -->
  <section class="reinfo_about about_v2  all-page-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5" data-aos="fade-right" :data-aos-duration="500">
          <div class="reinfo_img_box">
            <img alt="" class="img-fluid" src="/static-en/images/boss.jpg"/>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-right">
          <div class="reinfo_content_box wow fadeInRight">
            <h3 :data-aos-duration="500" data-aos="fade-right"><i aria-hidden="true"
                                                                  class="fa fa-pencil-square fa-1"></i>CEO message</h3>
            <p data-aos="fade-right" :data-aos-duration="500">
              In 2050, the leaders of the earth, a rich mother full of life, began to take action to drastically reduce carbon dioxide emissions in order to protect the earth, humankind, and all life.
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              We will continue to protect this beautiful blue earth for the children of the future.
            </p>
            <p data-aos="fade-right" :data-aos-duration="500">
              Each person thinks about carbon dioxide reduction and does what he / she can do.
            </p>
            <p :data-aos-duration="500" data-aos="fade-right">
              I will be the part of it.
            </p>
            <div class="award_box">
              <div class="award_info">
                <img alt="" class="sign-img" src="/static-en/images/sign.png">
                <h4>RE Initiative Team</h4>
                <h5>Best Award {{(new Date().getFullYear())}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End reinfo_about section -->
</template>

<script>
import BreadCrumb from "../components-en/BreadCrumb";
export default {
  name: "About",
  components:{ BreadCrumb }
}
</script>
