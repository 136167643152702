<template>
  <div class="show-center">
    <!-- Start service_feature -->
    <section class="reinfo_service service_v1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center no-margin-bottom" data-aos="fade-right">
              <h3>
                <i class="fa fa-battery-full icon-hidde_v1"></i>
                 Storage Battery System
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--REI- AI storage battery System-->
    <section class="reinfo_about  battery-padding-top battery-padding-bottom">
      <div class="container">
        <div class="row align-items-center pc-show_v1">
            <img src="/static-en/images/battery_bg.png" style="width:100%;"/>
        </div>  
        <div class="row align-items-center mobile-show_v1">
            <img src="/static-en/images/battery_bg_m.png" style="width:100%;"/>
        </div>
      </div>
    </section>
    <!--REI- AI storage battery System-200K-->
    <section class="reinfo_about battery_v1 battery-padding-top battery-padding-bottom_v1">
      <div class="container">
        <div class="row align-items-center">
          <!--Title-->
          <div class="col-lg-12">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-200K
              </h5>
            </div>
          </div>
          <!--Image-->
          <div class="col-lg-6">
            <div class=" align-center">
              <img class="img-200K" src="/static-en/images/200K.png"/>
            </div>
            <div :data-aos-duration="500" class="img-content" data-aos="fade-right">
              *Internal image
            </div>
          </div>
          <!--Table-->
          <div class="col-lg-6 subbox">
            <Table_el :columns="columns_200k" :titles="titles"/>
          </div>
        </div>
      </div>
    </section>

    <!--REI- AI storage battery System-100K-->
    <section class="reinfo_about battery_v1 battery-padding-top battery-padding-bottom_v1">
      <div class="container">
        <div class="row align-items-center">
          <!--Title-->
          <div class="col-lg-12">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-100K
              </h5>
            </div>
          </div>
          <!--image-->
          <div class="col-lg-6 align-center">
            <img src="/static-en/images/100k_1.png" width="60%"/>
          </div>
          <div class="col-lg-6 align-center">
            <img src="/static-en/images/100k-2.png" width="80%"/>
            <img src="/static-en/images/100k-3.png" style="
                        position: absolute;
                        z-index: 9;
                        top: 60%;
                        left: 60%;
                        height: 50%;"/>
          </div>
          <!--mobile-->
          <div class="col-lg-6 mobile-show_v1 ">
            <Battery_el :isMobie="true"/>
          </div>
          <div class="col-lg-6 subbox mobile-show_v1">
            <Table_el :columns="columns_100k" :titles="titles"/>
          </div>

          <!--PC-->
          <div class="col-lg-6 subbox pc_show_v1">
            <Table_el :columns="columns_100k" :titles="titles"/>
          </div>
          <div class="col-lg-6 pc_show_v1">
            <Battery_el :isMobie="false"/>
          </div>
          <div class="col-lg-12" style="margin-top:60px">
            <div class="battery-left-item-h5">
              <h5 :data-aos-duration="500" class="fs19" data-aos="fade-right">
                REI- AI storage battery System-50K
              </h5>
            </div>
          </div>
          <div class="col-lg-6 subbox">
            <Table_el :columns="columns_50k" :titles="titles"/>
          </div>
          <div class="col-lg-6 align-center">
            <img src="/static/images/50k_1.jpg" style="width:70%"/>
          </div>

        </div>
      </div>
    </section>
    <!--PDF View-->
    <section class="reinfo_blog battery_v1 battery-padding-bottom_v1">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div :data-aos-duration="500" class="section_title text-center" data-aos="fade-right">
              <h3>
                <i aria-hidden="true" class="fa fa-file-text"></i>
                 Storage Battery System Pamphlet
              </h3>
            </div>
          </div>
        </div>
        <div :data-aos-duration="500" class="row align-items-center pdf-View" data-aos="fade-right">
          <iframe :src="src" frameborder="0" height="100%" width="100%"></iframe>
        </div>
      </div>
    </section>
    <!--メンテナンス体制-->
    <section class="reinfo_blog battery_v1 little-padding-bottom">
      <div class="container text-center">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <h3 data-aos="fade-right" style="margin-top:15px;" :data-aos-duration="500" >
                <i aria-hidden="true" class="fa fa-tools"></i>
                REI-AI storage battery system Management system
              </h3>
              <h5 :data-aos-duration="500" data-aos="fade-right" style="margin-top:15px;">
                Centralized management from order contract to construction and maintenance using ANDPAD system.
              </h5>
              <div style="width:100%;height:auto">
                <a href="https://lp.andpad.jp/" target="_blank" :data-aos-duration="500" data-aos="fade-right">
                  <img :data-aos-duration="500" class="ap_logo" data-aos="fade-right"
                       src="/static-en/images/ap_logo_h.jpg"/>
                </a>
                <h6 :data-aos-duration="500" data-aos="fade-right" style="color:#7a808d;font-size:14px;line-height:30px;">
                   We share only the necessary information from sales staff to construction staff, <br/>
                   And have established a safe and reliable management system so that each department can respond smoothly.
                </h6>
                <img :data-aos-duration="500" class="management-img" data-aos="fade-right"
                    src="/static-en/images/management.png"/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- End reinfo_feature -->
  </div>
</template>

<script>
const titles = ['Object', 'Contents', 'Remarks'];
// REI- AI storage battery System-200K
const columns_200k = [
  {item: 'Storage capacity', text: '200kWh', memo: '（214kWh）'},
  {item: 'Dimensions (mm)', text: 'W:1450×D:1100×H:2200×２', memo: '2 Boxes'},
  {item: 'Weight', text: '2.1t＋1.8t　Total 3.9t', memo: ''},
  {item: 'System PCS', text: '200V　50/60Hz　3-phase 3-wire　60kW', memo: 'Output limit possible'},
  {item: 'Output during a power outage', text: 'Maximum 60kW　', memo: 'Automatic switching option'},
  {item: 'Solar MPPT', text: 'DC/DC　60kW', memo: 'Charge with direct current'},
  {item: 'Operating temperature', text: '-20～60℃', memo: 'Air conditioner'},
  {item: 'Display', text: '10 inch touch panel', memo: ''},
  {item: 'AI learning', text: 'Yes', memo: ''},
  {item: 'Solar power generation forecast', text: 'Yes', memo: ''},
  {item: 'Installation', text: 'Indoor/Outdoor', memo: 'Salt damage painting is optional'},
  {item: 'Fire detector', text: 'Smoke, temperature, gas detection', memo: ''},
  {item: 'Fire extinguishing equipment', text: 'Heptafluoropropane', memo: 'Option'},
  {item: 'Cooperation with other devices', text: 'Diesel power generation', memo: 'Option'},
  {item: 'Number of cycles', text: '7300 cycles', memo: '-'},
  {item: 'DOD', text: '80%', memo: '-'}
];
// REI- AI storage battery System-100K
const columns_100k = [
  {item: 'Storage capacity', text: '100kWh', memo: '（107kWh）'},
  {item: 'Dimensions (mm)', text: 'W:1450×D:1100×H:2200', memo: ''},
  {item: 'Weight', text: '2.1t', memo: ''},
  {item: 'System PCS', text: '200V　50/60Hz　3-phase 3-wire　60kW', memo: 'Output limit possible'},
  {item: 'Output during a power outage', text: 'Maximum 60kW　', memo: 'Automatic switching option'},
  {item: 'Solar MPPT', text: 'DC/DC　60kW', memo: 'Option'},
  {item: 'Operating temperature', text: '-20～60℃', memo: 'Air conditioner'},
  {item: 'Display', text: '10 inch touch panel', memo: ''},
  {item: 'AI learning', text: 'Yes', memo: ''},
  {item: 'Solar power generation forecast', text: 'Yes', memo: 'Option'},
  {item: 'Installation', text: 'Indoor/Outdoor', memo: 'Salt damage painting is optional'},
  {item: 'Fire detector', text: 'Smoke, temperature, gas detection', memo: ''},
  {item: 'Fire extinguishing equipment', text: 'Heptafluoropropane', memo: 'Option'},
  {item: 'Cooperation with other devices', text: 'Diesel power generation', memo: 'Option'},
  {item: 'Number of cycles', text: '7300 cycles', memo: '-'},
  {item: 'DOD', text: '80%', memo: '-'}
];
const columns_50k = [
  {item: 'Storage capacity', text: 'W:1450×D:1100×H:2200', memo: ''},
  {item: 'Weight', text: '1.5t', memo: ''},
  {item: 'System PCS', text: '200V　50/60Hz　3-phase 3-wire　30kW', memo: 'Output limit possible'},
  {item: 'Output during a power outage', text: 'Maximum 30kW　', memo: 'Automatic switching option'},
  {item: 'Solar MPPT', text: 'DC/DC　30kW', memo: 'Option'},
  {item: 'Operating temperature', text: '-20～60℃', memo: 'Air conditioner'},
  {item: 'Display', text: '10 inch touch panel', memo: ''},
  {item: 'AI learning', text: 'Yes', memo: ''},
  {item: 'Solar power generation forecast', text: 'Yes', memo: 'Option'},
  {item: 'Installation', text: 'Indoor/Outdoor', memo: 'Salt damage painting is optional'},
  {item: 'Fire detector', text: 'Smoke, temperature, gas detection', memo: ''},
  {item: 'Fire extinguishing equipment', text: 'Heptafluoropropane', memo: 'Option'},
  {item: 'Cooperation with other devices', text: 'Diesel power generation', memo: 'Option'},
  {item: 'Number of cycles', text: '7300 cycles', memo: '-'},
  {item: 'DOD', text: '80%', memo: '-'}
];
// メンテナンス体制
const maintenances = [
  {
    href: '/static-en/pdf/battery-word.pdf',
    src: '/static-en/images/portfolio_1.jpg',
    text: 'Maintenance contract system ZMP'
  },
  {
    href: '/static-en/pdf/battery-word.pdf',
    src: '/static-en/images/portfolio_2.jpg',
    text: '24-hour backup system'
  },
  {
    href: '/static-en/pdf/battery-word.pdf',
    src: '/static-en/images/portfolio_3.jpg',
    text: 'Human resource development'
  }
]
import Table_el from "../components-en/Table";
import Battery_el from "../components-en/Battery100k";

export default {
  name: "Battery",
  components: {Table_el, Battery_el},
  data() {
    return {
      viewerUrl: '/static-en/pdfVeiw/web/viewer.html',
      fileUrl: '/static-en/pdf/pamphlet.pdf',
      columns_100k,
      columns_200k,
      columns_50k,
      titles,
      maintenances
    }
  },
  computed: {
    src() {
      return `${this.viewerUrl}?file=${encodeURIComponent(this.fileUrl)}&zoom=page-width`
    }
  }
}
</script>
