<template>
  <footer class="reinfo_footer ">
    <div class="widget_wrapper  all-page-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 footer-box">
            <div class="widget widegt_about">
              <div class="widget_title">
                <router-link to="/en"><img alt="" class="img-fluid footerlogo" src="/static-en/images/footer_logo.png">
                </router-link>
              </div>
              <p>
                RE Initiative Co., Ltd. is a company that contributes to the global environment through Storage battery systems, Wind power generation and Solar power generation.
              </p>
              <ul class="social" data-aos="fade-right" :data-aos-duration="500">
                <li><router-link to="#"><i class="fab fa-facebook-f"></i></router-link></li>
                <li><router-link to="#"><i class="fab fa-twitter"></i></router-link></li>
                <li><router-link to="#"><i class="fab fa-instagram"></i></router-link></li>
              </ul>
            </div>
          </div>
          <div v-show="showItems" class="col-lg-4 col-md-6 col-sm-12 footer-box1">
            <div class="widget widget_link">
              <div class="widget_title" data-aos="fade-right" :data-aos-duration="500">
                <h4>Quick Links</h4>
              </div>
              <ul>
                <li><router-link to="/about-en">About</router-link></li>
                <li><router-link to="/service-en">Service</router-link></li>
                <li><router-link to="/access-en">Access</router-link></li>
                <li><router-link to="/contact-en">Contact Us</router-link></li>
              </ul>
            </div>
          </div>
          <div v-show="showItems" class="col-lg-4 col-md-6 col-sm-12 footer-box2">
            <div class="widget widget_contact">
              <div class="widget_title">
                <h4>Contact</h4>
              </div>
              <div class="contact_info">
                <div class="single_info">
                  <div class="icon">
                    <i class="fas fa-phone-alt fa-1-5"></i>
                  </div>
                  <div class="info">
                    <p>TEL: <a :href="'tel:'+HomeInfo_EN.Tel">{{HomeInfo_EN.Tel}}</a></p>
                  </div>
                </div>
                <div class="single_info">
                  <div class="icon">
                    <i class="fas fa-envelope fa-1-5"></i>
                  </div>
                  <div class="info">
                    <p>E-mail: <a :href="'mailto:'+HomeInfo_EN.Email">{{HomeInfo_EN.Email}}</a></p>
                  </div>
                </div>
                <div class="single_info">
                  <div class="icon">
                    &nbsp;<i class="fas fa-map-marker-alt fa-1-5  icon-style"></i>
                  </div>
                  <div class="info">
                    {{ HomeInfo_EN.PostCode }} <p>
                    <span>{{ HomeInfo_EN.Address }}</span><span>{{ HomeInfo_EN.Building }}</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright_text">
              <p>Copyright &copy;
                  {{(new Date().getFullYear())}}
                RE Initiative Co., Ltd. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-backtop></el-backtop>
    </div>
  </footer>
</template>

<script>
import {ref,onMounted,onUnmounted} from "vue";
export default {
  name: "Footer",
  setup(){
    const showItems = ref(true)
    function hidden() {
      if (window.innerWidth < 767) {
        showItems.value = false
      }else {
        showItems.value = true
      }
    }
    onMounted(() => {
      hidden();
    })
    window.addEventListener('resize', hidden)
    onUnmounted(() => {
      window.removeEventListener('resize', hidden)
    })
    return{showItems}
  }
}
</script>

<style scoped>

</style>
