<template>
  <!-- Start reinfo_breadcrumb section -->
  <BreadCrumb PageName="Access"  ImageUrl="/static-en/images/breadcrumb_bg2.jpg"/>
  <!-- End reinfo_breadcrumb section -->

  <!-- Start reinfo_achivement section -->
  <section class="reinfo_achivement_V2 section_top all-page-padding">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-12  wow fadeInLeft access">
          <div class="reinfo_content_box access text-center">
            <h3><i aria-hidden="true" class="fa fa-compass fa-1 icon-hidde_v1"></i>Access</h3>
          </div>
          <h4 data-aos="fade-right" :data-aos-duration="500"><i class="fas fa-map-marker-alt"></i>Adress</h4>
          <p :data-aos-duration="500" class="access_margin_v1" data-aos="fade-right">
              Tokyo Office<br/>{{ HomeInfo_EN.PostCode }} <br/> {{ HomeInfo_EN.Address }}<br/> {{ HomeInfo_EN.Building }}</p>
          <div class="reinfo_content_box">
            <h4><i class="fa fa-car fa-1" data-aos="fade-right" :data-aos-duration="500"></i>Adress Method</h4>
            <div v-if="isMobile()" data-aos="fade-right" :data-aos-duration="500">
              <p class="access_margin_v2"><i class="fas fa-map-marker-alt"></i><i class="fa fa-subway i-color"
                                                                                  aria-hidden="true"></i>By Train<br>
                1-minute walk from the South Exit of Hamamatsucho Station via the World Trade Center Building South Tower passage.<br>
                …… Hamamatsucho boarding route …… <br>
                JR Yamanote Line<br>
                JR Keihin Tohoku Line<br>
                Tokyo Monorail <br><br/>
                …… …… Access from Hamamatsu Station to major stations …… <br>
                6-minutes to "Tokyo" station (using JR Yamanote line)<br>
                13-minutes to "Haneda Airport International Terminal" station (using Tokyo Monorail)<br>
                19-minutes to "Haneda Airport Terminal 2" station (using Tokyo Monorail)<br><br/>
              </p>
              <p class="access_margin_v1 line" data-aos="fade-right" :data-aos-duration="500"><i class="fa fa-car i-color" aria-hidden="true"></i>In the case of a car<br>
                …… …… …… ………… …… …… ………… <br>
                6-minutes from the Shiba Park Inner Loop Exit on the Metropolitan Expressway Inner Circular Route<br>
                There is no parking lot, so please park at a nearby pay parking lot.
                <br/>
              </p>
            </div>
            <div v-else>
              <p class="access_margin_v2" data-aos="fade-right" :data-aos-duration="500"><i
                  class="fa fa-subway i-color"></i>By Train<br>
                1-minute walk from the South Exit of Hamamatsucho Station via the World Trade Center Building South Tower passage.<br>
                 …… …… Hamamatsucho boarding route …… …… <br>
                JR Yamanote Line<br>
                JR Keihin Tohoku Line<br>
                Tokyo Monorail <br><br/>
                 …… …… Access from Hamamatsu Station to major stations …… …… <br>
                
                6-minutes to "Tokyo" station (using JR Yamanote line)<br>
                13-minutes to "Haneda Airport International Terminal" station (using Tokyo Monorail)<br>
                19-minutes to "Haneda Airport Terminal 2" station (using Tokyo Monorail)<br><br/>
              </p>
              <p class="access_margin_v1 line" data-aos="fade-right" :data-aos-duration="500"><i class="fa fa-car i-color" aria-hidden="true"></i>In the case of a car<br>
                …… …… …… ………… …… …… ………… …… …… …… …… ……<br>
                6-minutes from the Shiba Park Inner Loop Exit on the Metropolitan Expressway Inner Circular Route<br>
                There is no parking lot, so please park at a nearby pay parking lot.
              </p>
            </div>
          </div>
          <div class="reinfo_content_box" data-aos="fade-right" :data-aos-duration="500">
            <h4><i class="fa fa-phone-square fa-1"></i>Contact Address</h4>
            <p class="access_margin_v1">Tokyo Office<br/>TEL. <a :href="'tel:'+HomeInfo_EN.Tel">{{HomeInfo_EN.Tel}}</a></p>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <div class="reinfo_content_box access" data-aos="fade-right" :data-aos-duration="500">
            <h3><i aria-hidden="true" class="fa fa-map-marker-alt fa-1 icon-hidde_v1"></i>{{ HomeInfo_EN.CompanyName }} Address
            </h3>
            <h4 class="map-marker access_margin_v1">Map</h4>
          </div>
          <iframe id="map" allowfullscreen=""
                  class="map_size" loading="lazy"
                  data-aos="fade-right" :data-aos-duration="500"
                  :src="HomeInfo_EN.MapUrl"></iframe>
        </div>
      </div>
    </div>

  </section>
  <!-- End reinfo_achivement section -->
<section></section>
</template>

<script>
import BreadCrumb from "../components-en/BreadCrumb";

export default {
  name: "Access",
  components: { BreadCrumb},
  methods: {
    isMobile : function() {
       if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          return true; // 移动端
        }else{
          return false; // PC端
        }
    }
  }
}
</script>

<style scoped>
.i-color {
  color:#7a808d;
}
</style>
